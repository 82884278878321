import {
  IconPrefix,
  IconName,
  IconDefinition,
  findIconDefinition,
} from "@fortawesome/fontawesome-svg-core";

function findIcon(
  icon: string,
  prefix: IconPrefix = "fal",
): IconDefinition | null {
  const dashIdx = icon.indexOf("-");
  if (dashIdx === -1) {
    return null;
  }

  // This assertion is technically unsafe, however, the findIconDefintion
  // will return undefined if iconName is invalid.
  const iconName = icon.substring(dashIdx + 1) as IconName;

  const lookup = { prefix, iconName };
  return findIconDefinition(lookup);
}

export default findIcon;
