import React from "react";
import gql from "graphql-tag";
import formatDate from "date-fns/format";
import Tippy from "@tippyjs/react";
import DynamicIcon from "./DynamicIcon";
import { ExpensesListItemFragment_ExpenseFragment } from "../generated/graphql";
import isStringNullOrEmpty from "../utils/is-string-null-or-empty";
import styles from "./ExpensesList.module.css";

interface Props {
  expense: ExpensesListItemFragment_ExpenseFragment;
}

const TIMESTAMP_FORMAT = "MMM d, yyyy";
const TOOLTIP_TIMESTAMP_FORMAT = "h:mm b";

function ExpensesListItem({ expense }: Props) {
  const formattedAmount = expense.amount.toFixed(2);

  return (
    <>
      <Tippy
        content={formatDate(expense.timestamp * 1000, TOOLTIP_TIMESTAMP_FORMAT)}
      >
        <div>{formatDate(expense.timestamp * 1000, TIMESTAMP_FORMAT)}</div>
      </Tippy>
      <div>
        <DynamicIcon
          icon={expense.category.icon}
          containerClassName={styles.categoryIcon}
        />
        {isStringNullOrEmpty(expense.description)
          ? expense.category.title
          : expense.description}
      </div>
      <div className={styles.amountCell}>
        <span>{expense.currency}</span>
        <span>{formattedAmount}</span>
      </div>
    </>
  );
}

export const ExpensesListItemFragment = gql`
  fragment ExpensesListItemFragment_Expense on Expense {
    currency
    amount
    category {
      icon
      title
    }
    description
    timestamp
  }
`;

export default ExpensesListItem;
