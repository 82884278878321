import React from "react";
import gql from "graphql-tag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import {
  InterestedBooksListItemPriceComparisonCellFragment_LatestPriceFragment,
  InterestedBooksListItemPriceComparisonCellFragment_ReferencePriceFragment,
} from "../generated/graphql";
import styles from "./InterestedBooksList.module.css";

interface Props {
  label: string;
  latestPrice:
    | InterestedBooksListItemPriceComparisonCellFragment_LatestPriceFragment
    | null
    | undefined;
  referencePrice:
    | InterestedBooksListItemPriceComparisonCellFragment_ReferencePriceFragment
    | null
    | undefined;
}

function InterestedBooksListItemPriceComparisonCell({
  label,
  latestPrice,
  referencePrice,
}: Props) {
  if (latestPrice == null || referencePrice == null) {
    return (
      <div className={styles.priceComparisonCell}>
        <span className={styles.inlineLabel}>{label}: </span>N/A
      </div>
    );
  }

  const percentage = 100 * (latestPrice.price / referencePrice.price - 1);
  const absPercentage = Math.abs(percentage);
  return (
    <Tippy
      content={`Price on ${
        referencePrice.date
      }: $${referencePrice.price.toFixed(2)}`}
    >
      <div className={styles.priceComparisonCell}>
        <span className={styles.inlineLabel}>{label}: </span>
        {percentage > 0 ? (
          <FontAwesomeIcon icon={["fal", "caret-up"]} color="red" />
        ) : (
          <FontAwesomeIcon icon={["fal", "caret-down"]} color="green" />
        )}
        <span className={styles.priceComparisonPercentageLabel}>
          {absPercentage.toFixed(0)}%
        </span>
      </div>
    </Tippy>
  );
}

export const InterestedBooksListItemPriceComparisonCellFragment = gql`
  fragment InterestedBooksListItemPriceComparisonCellFragment_LatestPrice on BookPrice {
    price
  }
  fragment InterestedBooksListItemPriceComparisonCellFragment_ReferencePrice on BookPrice {
    date
    price
  }
`;

export default InterestedBooksListItemPriceComparisonCell;
