import React, { ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./LoadingField.module.css";

interface Props {
  label: string;
  hint?: ReactNode;
}

function LoadingField({ label, hint }: Props) {
  return (
    <div className="field">
      <div className="label">{label}</div>
      <div className={styles.iconContainer}>
        <FontAwesomeIcon
          icon={["fal", "spinner-third"]}
          spin={true}
          size="2x"
        />
        {hint && <p className="help">{hint}</p>}
      </div>
    </div>
  );
}

export default LoadingField;
