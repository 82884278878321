import React from "react";
import { useForm } from "react-hook-form";
import CurrencySelector from "./CurrencySelector";
import TextField from "./TextField";
import Select from "./Select";
import Button from "./Button";
import { SimpleBudgetPeriod } from "../generated/graphql";

export interface SimpleBudgetInput {
  currency: string;
  amount: number;
  period: SimpleBudgetPeriod;
}

interface SimpleBudgetFieldValues {
  currency: string;
  amount: string;
  period: SimpleBudgetPeriod;
}

interface Props {
  defaultValues?: SimpleBudgetInput;
  onSubmit: (simpleBudget: SimpleBudgetInput) => void;
  isSaving?: boolean;
}

const PERIOD_OPTIONS = [
  {
    value: SimpleBudgetPeriod.Daily,
    label: "Daily",
  },
  {
    value: SimpleBudgetPeriod.Monthly,
    label: "Monthly",
  },
];

const toFieldValues = (input: SimpleBudgetInput): SimpleBudgetFieldValues => ({
  currency: input.currency,
  amount: input.amount.toString(),
  period: input.period,
});

const fromValidatedFieldValues = (
  input: SimpleBudgetFieldValues,
): SimpleBudgetInput => ({
  currency: input.currency,
  amount: parseFloat(input.amount),
  period: input.period,
});

function SimpleBudgetForm({
  defaultValues,
  onSubmit,
  isSaving = false,
}: Props) {
  const { register, handleSubmit, errors } = useForm<SimpleBudgetFieldValues>({
    defaultValues:
      defaultValues != null
        ? toFieldValues(defaultValues)
        : {
            currency: "SGD",
            period: SimpleBudgetPeriod.Monthly,
          },
  });

  const onFormSubmit = (input: SimpleBudgetFieldValues) => {
    onSubmit(fromValidatedFieldValues(input));
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <CurrencySelector
        use={errors.currency != null ? "danger" : undefined}
        name="currency"
        label="Currency"
        ref={register({
          required: "Please enter a currency for this budget.",
        })}
        hint={errors.currency?.message}
      />
      <TextField
        use={errors.amount != null ? "danger" : undefined}
        type="number"
        name="amount"
        label="Amount"
        ref={register({
          required: "Please enter an amount for this budget.",
          min: {
            value: 0.01,
            message: "Please enter a positive amount for this budget.",
          },
        })}
        hint={errors.amount?.message}
      />
      <Select
        use={errors.period != null ? "danger" : undefined}
        name="period"
        label="Period"
        promptLabel="Select a period"
        options={PERIOD_OPTIONS}
        ref={register({
          required: "Please select how frequently does the budget renew.",
        })}
        hint={errors.period?.message}
      />

      <Button use="primary" label="Save" isDisabled={isSaving} />
    </form>
  );
}

export default SimpleBudgetForm;
