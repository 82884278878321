import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { produce } from "immer";
import { Link } from "react-router-dom";
import Page from "./Page";
import RecurringExpensesList, {
  RecurringExpensesListFragment,
} from "../components/RecurringExpensesList";
import useEnsureSignedIn from "../hooks/use-ensure-signed-in";
import flattenArray from "../utils/flatten-array";
import {
  RecurringExpensesListQuery,
  RecurringExpensesListQueryVariables,
} from "../generated/graphql";

const RECURRING_EXPENSES_LIST_PAGE = gql`
  query RecurringExpensesList($after: String) {
    recurringExpenses(first: 30, after: $after) {
      nodes {
        ...RecurringExpensesListFragment_RecurringExpense
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${RecurringExpensesListFragment}
`;

function RecurringExpensesListPage() {
  useEnsureSignedIn();

  const { data, loading, fetchMore } = useQuery<
    RecurringExpensesListQuery,
    RecurringExpensesListQueryVariables
  >(RECURRING_EXPENSES_LIST_PAGE, {
    fetchPolicy: "cache-and-network",
    partialRefetch: true,
  });

  if (data == null) {
    return <div>Loading...</div>;
  }

  const loadNextPage = () => {
    fetchMore({
      variables: {
        after: data.recurringExpenses.pageInfo.endCursor,
      },
      updateQuery(previousQueryResult, { fetchMoreResult }) {
        if (fetchMoreResult == null) {
          return previousQueryResult;
        }

        return produce(previousQueryResult, result => {
          result.recurringExpenses.nodes = [
            ...(previousQueryResult.recurringExpenses.nodes || []),
            ...(fetchMoreResult.recurringExpenses.nodes || []),
          ];
          result.recurringExpenses.pageInfo =
            fetchMoreResult.recurringExpenses.pageInfo;
        });
      },
    });
  };

  const recurringExpenses = flattenArray(data.recurringExpenses.nodes);
  return (
    <Page
      title="Recurring Expenses"
      actionButton={<Link to="/expenses/recurring/new">Add</Link>}
    >
      <RecurringExpensesList
        recurringExpenses={recurringExpenses}
        isLoadingMore={loading}
        loadMore={
          data.recurringExpenses.pageInfo.hasNextPage ? loadNextPage : undefined
        }
      />
    </Page>
  );
}

export default RecurringExpensesListPage;
