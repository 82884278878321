import React, { useEffect, useMemo } from "react";
import Chart from "chart.js";
import { parse } from "date-fns";
import { useAppMenuVisibility } from "../context/app-menu-visibility";
import useChart from "../hooks/use-chart";

export interface BookPrice {
  date: string;
  price: number;
}

interface Props {
  prices: BookPrice[];
}

function BookPriceChart({ prices }: Props) {
  const options = useMemo((): Chart.ChartConfiguration => {
    const labels = prices.map(({ date }) =>
      parse(date, "yyyy-MM-dd", new Date()),
    );
    const data = prices.map(({ price }) => price);

    return {
      type: "line",
      data: {
        labels,
        datasets: [{ data, backgroundColor: "#8dd3c7" }],
      },
      options: {
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              type: "time",
              time: {
                unit: "day",
                tooltipFormat: "MMMM D",
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                callback(value: number) {
                  return `$${value.toFixed(2)}`;
                },
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label(tooltipItem, data) {
              const value = data.datasets![0].data![
                tooltipItem.index!
              ] as number;
              return `$${value.toFixed(2)}`;
            },
          },
        },
      },
    };
  }, [prices]);

  const { register, chart } = useChart(options);

  const [isAppMenuVisible] = useAppMenuVisibility();

  useEffect(() => {
    // By right the library handles resizes, but somehow it doesn't
    // work when the app menu toggles.
    chart?.resize();
  }, [chart, isAppMenuVisible]);

  return <canvas ref={register} />;
}

export default BookPriceChart;
