import React from "react";
import gql from "graphql-tag";
import DynamicIcon from "./DynamicIcon";
import {
  RecurringExpensesListItemFragment_RecurringExpenseFragment,
  RecurringExpenseFrequency,
} from "../generated/graphql";
import styles from "./RecurringExpensesList.module.css";

interface Props {
  recurringExpense: RecurringExpensesListItemFragment_RecurringExpenseFragment;
}

const FREQUENCY_TO_PERIOD: Record<RecurringExpenseFrequency, string> = {
  MONTHLY: "month",
};

function RecurringExpensesListItem({ recurringExpense }: Props) {
  return (
    <>
      <div>
        <DynamicIcon
          icon={recurringExpense.category.icon}
          containerClassName={styles.categoryIcon}
        />
        {recurringExpense.title}
      </div>
      <div>
        <span className={styles.inlineLabel}>Validity: </span>
        {recurringExpense.validFrom} -{" "}
        {recurringExpense.validUntil ?? "Present"}
      </div>
      <div className={styles.amountCell}>
        <span className={styles.inlineLabel}>Amount: </span>
        <span>{recurringExpense.currency}</span>
        <span>
          {recurringExpense.amount.toFixed(2)} /{" "}
          {FREQUENCY_TO_PERIOD[recurringExpense.frequency]}
        </span>
      </div>
    </>
  );
}

export const RecurringExpensesListItemFragment = gql`
  fragment RecurringExpensesListItemFragment_RecurringExpense on RecurringExpense {
    title
    currency
    amount
    frequency
    validFrom
    validUntil
    category {
      icon
    }
  }
`;

export default RecurringExpensesListItem;
