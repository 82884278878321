import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import produce from "immer";
import { Link } from "react-router-dom";
import Page from "./Page";
import ExpensesList, { ExpensesListFragment } from "../components/ExpensesList";
import useEnsureSignedIn from "../hooks/use-ensure-signed-in";
import flattenArray from "../utils/flatten-array";
import {
  ExpensesListPageQuery,
  ExpensesListPageQueryVariables,
} from "../generated/graphql";

const EXPENSES_LIST_PAGE_QUERY = gql`
  query ExpensesListPage($after: String) {
    expenses(first: 30, after: $after) {
      nodes {
        ...ExpensesListFragment_Expense
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${ExpensesListFragment}
`;

function ExpensesListPage() {
  useEnsureSignedIn();

  const { data, loading, fetchMore } = useQuery<
    ExpensesListPageQuery,
    ExpensesListPageQueryVariables
  >(EXPENSES_LIST_PAGE_QUERY, {
    fetchPolicy: "cache-and-network",
    partialRefetch: true,
  });

  if (data == null) {
    return <div>Loading...</div>;
  }

  const fetchNextPage = () => {
    fetchMore({
      variables: {
        after: data.expenses.pageInfo.endCursor,
      },
      updateQuery(previousResult, { fetchMoreResult }) {
        if (fetchMoreResult == null) {
          return previousResult;
        }

        return produce(previousResult, result => {
          result.expenses.nodes = [
            ...(previousResult.expenses.nodes || []),
            ...(fetchMoreResult.expenses.nodes || []),
          ];
          result.expenses.pageInfo = fetchMoreResult.expenses.pageInfo;
        });
      },
    });
  };

  const expenses = flattenArray(data.expenses.nodes);

  return (
    <Page title="Expenses" actionButton={<Link to="/expenses/new">Add</Link>}>
      <ExpensesList
        expenses={expenses}
        isLoadingMore={loading}
        loadMore={
          data.expenses.pageInfo.hasNextPage ? fetchNextPage : undefined
        }
      />
    </Page>
  );
}

export default ExpensesListPage;
