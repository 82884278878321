import React from "react";
import gql from "graphql-tag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import InterestedBooksListItem, {
  InterestedBooksListItemFragment,
} from "./InterestedBooksListItem";
import Button from "../components/Button";
import { InterestedBooksListFragment_InterestedBookFragment } from "../generated/graphql";
import styles from "./InterestedBooksList.module.css";

interface Props {
  books: InterestedBooksListFragment_InterestedBookFragment[];
  loadMore?: () => void;
  isLoadingMore?: boolean;
}

function InterestedBooksList({
  books,
  loadMore,
  isLoadingMore = false,
}: Props) {
  return (
    <div>
      <div className={classNames(styles.headerRow, styles.row)}>
        <div className={classNames(styles.titleCell, styles.headerCell)}>
          Title
        </div>
        <div className={classNames(styles.priceCell, styles.headerCell)}>
          Latest Price
        </div>
        <div
          className={classNames(styles.priceComparisonCell, styles.headerCell)}
        >
          1-wk ∆
        </div>
        <div
          className={classNames(styles.priceComparisonCell, styles.headerCell)}
        >
          1-mo ∆
        </div>
      </div>

      {books.map(book => (
        <InterestedBooksListItem key={book.id} book={book} />
      ))}
      {isLoadingMore && (
        <div className={styles.alignCenter}>
          <FontAwesomeIcon
            icon={["fal", "spinner-third"]}
            spin={true}
            size="lg"
          />
          <span className={styles.loadingMessage}>
            Loading more expenses...
          </span>
        </div>
      )}
      {loadMore && !isLoadingMore && (
        <div className={styles.alignCenter}>
          <Button use="info" label="Load More" onClick={loadMore} />
        </div>
      )}
    </div>
  );
}

export const InterestedBooksListFragment = gql`
  fragment InterestedBooksListFragment_InterestedBook on InterestedBook {
    id
    ...InterestedBooksListItemFragment_InterestedBook
  }
  ${InterestedBooksListItemFragment}
`;

export default InterestedBooksList;
