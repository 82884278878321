export interface MenuItem {
  title: string;
  to: string;
}

interface ApplicationMenu {
  path: string;
  menuItems: MenuItem[];
}

const APPLICATION_MENUS: ApplicationMenu[] = [
  {
    path: "/expenses",
    menuItems: [
      {
        title: "Expenses",
        to: "/expenses",
      },
      {
        title: "All Expenses",
        to: "/expenses/all",
      },
      {
        title: "Recurring Expenses",
        to: "/expenses/recurring",
      },
      {
        title: "Expense Categories",
        to: "/expenses/categories",
      },
    ],
  },
  {
    path: "/interested_books",
    menuItems: [
      {
        title: "Interested Books",
        to: "/interested_books",
      },
    ],
  },
];

export default APPLICATION_MENUS;
