import React, { useContext, useMemo } from "react";
import classNames from "classnames";

interface Props {
  isShown: boolean;
  onHide: () => void;
  children: React.ReactNode;
}

const ModalContext = React.createContext({
  onHide: () => {},
});

function Modal({ isShown, onHide, children }: Props) {
  const contextValue = useMemo(() => ({ onHide }), [onHide]);

  return (
    <ModalContext.Provider value={contextValue}>
      <div className={classNames("modal", { "is-active": isShown })}>
        <div className="modal-background" />
        <div className="modal-content">{children}</div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={onHide}
        />
      </div>
    </ModalContext.Provider>
  );
}

function useModalContext() {
  return useContext(ModalContext);
}

export default Modal;
export { useModalContext };
