import gql from "graphql-tag";
import { v4 as uuid } from "uuid";
import { Context } from "./context-type";
import {
  MutationAddNotificationArgs,
  AddNotificationResolverQuery,
  AddNotificationResolverQueryVariables,
  Notification,
} from "../generated/graphql";

const ADD_NOTIFICATION_RESOLVER = gql`
  query AddNotificationResolver {
    notifications @client {
      id
      message
      flashState
      type
    }
  }
`;

function addNotification(
  _: any,
  { message, flashState, type }: MutationAddNotificationArgs,
  { client }: Context,
): Notification {
  const data = client.readQuery<
    AddNotificationResolverQuery,
    AddNotificationResolverQueryVariables
  >({ query: ADD_NOTIFICATION_RESOLVER });

  const notifications = data?.notifications ?? [];

  const newNotification: Notification = {
    __typename: "Notification",
    id: uuid(),
    message,
    flashState,
    type: type ?? null,
  };

  client.writeData({
    data: {
      notifications: [...notifications, newNotification],
    },
  });

  return newNotification;
}

export default addNotification;
