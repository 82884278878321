import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { Link } from "react-router-dom";
import Page from "./Page";
import useEnsureSignedIn from "../hooks/use-ensure-signed-in";
import {
  ServicesListPageQuery,
  ServicesListPageQueryVariables,
} from "../generated/graphql";

const SERVICES_LIST_PAGE_QUERY = gql`
  query ServicesListPage {
    currentUser {
      connectedToInstapaper
      connectedToPocket
    }
  }
`;

function ServicesListPage() {
  useEnsureSignedIn();

  const { data } = useQuery<
    ServicesListPageQuery,
    ServicesListPageQueryVariables
  >(SERVICES_LIST_PAGE_QUERY, {
    fetchPolicy: "cache-and-network",
    partialRefetch: true,
  });

  if (data == null) {
    return <div>Loading...</div>;
  }

  return (
    <Page title="Services">
      <ul>
        <li>
          <Link to="/services/instapaper">Instapaper</Link> (
          {data.currentUser.connectedToInstapaper
            ? "Connected"
            : "Not Connected"}
          )
        </li>
        <li>
          <Link to="/services/pocket">Pocket</Link> (
          {data.currentUser.connectedToPocket ? "Connected" : "Not Connected"})
        </li>
      </ul>
    </Page>
  );
}

export default ServicesListPage;
