import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import APPLICATION_MENUS, { MenuItem } from "../configs/application-menus";

// "/my-path" => "/my-path"
// "/my-path/" => "/my-path"
function stripTrailingSlash(path: string): string {
  return path.endsWith("/") ? path.substr(0, path.length - 1) : path;
}

// Return if currentPath matches testPath
// Currently this is very rudimentary in the sense that we do prefix
// match with a little bit of smarts.
function matchRoute(currentPath: string, testPath: string): boolean {
  currentPath = stripTrailingSlash(currentPath);
  testPath = stripTrailingSlash(testPath);

  return testPath === currentPath || currentPath.startsWith(`${testPath}/`);
}

function useApplicationMenu(): MenuItem[] {
  const location = useLocation();
  const currentPath = location.pathname;

  const match = useMemo(() => {
    const matches = APPLICATION_MENUS.filter(({ path }) =>
      matchRoute(currentPath, path),
    );
    return matches.length > 0 ? matches[matches.length - 1] : null;
  }, [currentPath]);

  if (match == null) {
    return [];
  }

  return match.menuItems;
}

export default useApplicationMenu;
