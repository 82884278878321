import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import Page from "./Page";
import TextField from "../components/TextField";
import Button from "../components/Button";
import Notification from "../components/Notification";
import { setSessionToken } from "../storage/session-token-storage";
import useNotifications from "../hooks/use-notifications";
import {
  SignInPage_SignInMutation,
  SignInPage_SignInMutationVariables,
  NotificationType,
  NotificationFlashState,
} from "../generated/graphql";

const SIGN_IN_PAGE_SIGN_IN_MUTATION = gql`
  mutation SignInPage_SignIn($email: String!, $password: String!) {
    sessionToken: signIn(email: $email, password: $password)
  }
`;

function SignInPage() {
  const { addNotification } = useNotifications();

  const [searchParams] = useSearchParams({ redirect_to: "/" });
  const navigate = useNavigate();

  const { register, handleSubmit, errors } =
    useForm<SignInPage_SignInMutationVariables>();
  const [signInFailed, setSignInFailed] = useState(false);

  const [signIn, { loading, client }] = useMutation<
    SignInPage_SignInMutation,
    SignInPage_SignInMutationVariables
  >(SIGN_IN_PAGE_SIGN_IN_MUTATION, {
    async onCompleted({ sessionToken }) {
      if (sessionToken == null) {
        setSignInFailed(true);
      } else {
        client?.cache.writeData({
          data: {
            isSignedIn: true,
          },
        });
        setSessionToken(sessionToken);

        await addNotification(
          "Welcome back!",
          NotificationFlashState.NextPage,
          NotificationType.Success,
        );

        const redirectTo = searchParams.get("redirect_to") ?? "/";
        navigate(redirectTo);
      }
    },
  });

  const onSubmit = (variables: SignInPage_SignInMutationVariables) => {
    setSignInFailed(false);
    signIn({ variables });
  };

  return (
    <Page title="Sign In">
      {signInFailed && (
        <Notification use="danger">
          Incorrect email/password combination.
        </Notification>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          ref={register({ required: "Please enter your email." })}
          use={errors.email != null ? "danger" : undefined}
          type="email"
          name="email"
          label="Email"
          hint={errors.email?.message}
        />
        <TextField
          ref={register({ required: "Please enter your password." })}
          use={errors.password != null ? "danger" : undefined}
          type="password"
          name="password"
          label="Password"
          hint={errors.password?.message}
        />

        <Button use="primary" isDisabled={loading} label="Sign In" />
      </form>
    </Page>
  );
}

export default SignInPage;
