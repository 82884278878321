import { useEffect } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { useNavigate, useLocation } from "react-router-dom";
import {
  UseEnsureSignedInQuery,
  UseEnsureSignedInQueryVariables,
} from "../generated/graphql";

const USE_ENSURE_SIGNED_IN_QUERY = gql`
  query UseEnsureSignedIn {
    isSignedIn @client
  }
`;

function useEnsureSignedIn() {
  const { data } = useQuery<
    UseEnsureSignedInQuery,
    UseEnsureSignedInQueryVariables
  >(USE_ENSURE_SIGNED_IN_QUERY);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (data == null) {
      console.error(
        "Query response for isSignedIn is null. This should never happen.",
      );
      return;
    }

    if (!data.isSignedIn) {
      const redirectTo = location.pathname + location.search + location.hash;
      const encodedRedirectTo = encodeURIComponent(redirectTo);
      navigate(`/sign_in?redirect_to=${encodedRedirectTo}`);
    }
  }, [data, navigate, location.pathname, location.search, location.hash]);
}

export default useEnsureSignedIn;
