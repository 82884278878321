import React from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Link } from "react-router-dom";
import useEnsureSignedIn from "../hooks/use-ensure-signed-in";
import Page from "./Page";
import ModalLauncherButton from "../components/ModalLauncherButton";
import BunnyProfileForm, {
  BunnyProfileInput,
} from "../components/BunnyProfileForm";
import { useModalContext } from "../components/Modal";
import useNotifications from "../hooks/use-notifications";
import flattenArray from "../utils/flatten-array";
import {
  BunnyProfilesListPageQuery,
  BunnyProfilesListPageQueryVariables,
  CreateBunnyProfileMutation_CreateProfileMutation,
  CreateBunnyProfileMutation_CreateProfileMutationVariables,
  NotificationFlashState,
  NotificationType,
} from "../generated/graphql";

const BUNNY_PROFILES_LIST_PAGE_QUERY = gql`
  query BunnyProfilesListPage {
    bunnyProfiles {
      nodes {
        id
        name
      }
    }
  }
`;

const CREATE_BUNNY_PROFILE_MUTATION = gql`
  mutation CreateBunnyProfileMutation_CreateProfile($name: String!) {
    createBunnyProfile(name: $name) {
      ... on ValidationErrors {
        errors {
          field
          messages
        }
      }
    }
  }
`;

function CreateBunnyProfileForm() {
  const modal = useModalContext();
  const { addNotification } = useNotifications();

  const [createProfile, { loading: isSaving }] = useMutation<
    CreateBunnyProfileMutation_CreateProfileMutation,
    CreateBunnyProfileMutation_CreateProfileMutationVariables
  >(CREATE_BUNNY_PROFILE_MUTATION, {
    refetchQueries: ["BunnyProfilesListPage"],
  });

  const onSubmit = async (profile: BunnyProfileInput) => {
    const { data, errors } = await createProfile({ variables: profile });

    // TODO Handle this at link level
    if (errors != null && errors.length > 0) {
      const messages = errors.map(error => error.message);
      alert(`GraphQL errors:\n${messages.join("\n")}`);
      return;
    }

    if (data == null) {
      alert("Empty response from GraphQL mutation.");
      return;
    }

    if (data.createBunnyProfile?.__typename === "ValidationErrors") {
      const errors = data.createBunnyProfile.errors;
      const messages = errors.flatMap(({ field, messages }) => {
        return messages.map(message => `${field} ${message}`);
      });
      alert(`Validation errors:\n${messages.join("\n")}`);
      return;
    }

    await addNotification(
      "Profile created",
      NotificationFlashState.CurrentPage,
      NotificationType.Success,
    );
    modal.onHide();
  };

  return (
    <div className="box">
      <BunnyProfileForm onSubmit={onSubmit} isSaving={isSaving} />
    </div>
  );
}

function BunnyProfilesListPage() {
  useEnsureSignedIn();

  const { data } = useQuery<
    BunnyProfilesListPageQuery,
    BunnyProfilesListPageQueryVariables
  >(BUNNY_PROFILES_LIST_PAGE_QUERY, {
    fetchPolicy: "cache-and-network",
    partialRefetch: true,
  });

  if (data == null) {
    return <div>Loading...</div>;
  }

  const profiles = flattenArray(data.bunnyProfiles.nodes);

  return (
    <Page title="Bunny Profiles">
      <ModalLauncherButton use="success" label="New Profile">
        <CreateBunnyProfileForm />
      </ModalLauncherButton>
      <ul>
        {profiles.map(profile => (
          <li key={profile.id}>
            <Link to={`/bunny/profiles/${profile.id}`}>{profile.name}</Link>
          </li>
        ))}
      </ul>
    </Page>
  );
}

export default BunnyProfilesListPage;
