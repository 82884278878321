import React, { ReactNode } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import styles from "./NavigationBar.module.css";

export interface MenuItem {
  title: string;
  to: string;
}

interface Props {
  title?: string;
  actionButton?: ReactNode;
  onToggleMenu?: () => void;
}

function NavigationBar({ title, actionButton, onToggleMenu }: Props) {
  return (
    <div className={styles.container}>
      <span className={styles.actionContainer}>
        {onToggleMenu != null && (
          <button className={styles.menuButton} onClick={onToggleMenu}>
            <FontAwesomeIcon icon={["fal", "bars"]} size="2x" />
          </button>
        )}
      </span>
      <div className={styles.titleContainer}>
        <Link to="/" className={styles.appTitle}>
          Personal Assistant
        </Link>
        {title && (
          <span className={classNames("has-text-grey", styles.pageTitle)}>
            {title}
          </span>
        )}
      </div>
      <span
        className={classNames(
          styles.actionContainer,
          styles.rightActionContainer,
        )}
      >
        {actionButton}
      </span>
    </div>
  );
}

export default NavigationBar;
