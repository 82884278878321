const DEFAULT_COLOR_PALETTE = [
  "#8dd3c7",
  "#bebada",
  "#fb8072",
  "#80b1d3",
  "#fdb462",
  "#b3de69",
  "#fccde5",
  "#d9d9d9",
  "#bc80bd",
  "#ccebc5",
  "#ffed6f",
  "#ffffb3",
];

export default DEFAULT_COLOR_PALETTE;
