import React, { useMemo } from "react";
import gql from "graphql-tag";
import { useForm } from "react-hook-form";
import formatDate from "date-fns/format";
import add from "date-fns/add";
import TextField from "./TextField";
import Select from "./Select";
import Checkbox from "./Checkbox";
import Button from "./Button";
import LoadingField from "./LoadingField";
import ExpenseCategorySelector, {
  ExpenseCategoriesFragment,
} from "./ExpenseCategorySelector";
import CurrencySelector from "./CurrencySelector";
import {
  RecurringExpenseForm_ExpenseCategoriesFragment,
  RecurringExpenseFrequency,
} from "../generated/graphql";

export interface RecurringExpenseInput {
  title: string;
  currency: string;
  amount: number;
  frequency: RecurringExpenseFrequency;
  categoryId: string;
  validFrom: string;
  validUntil: string | null;
}

interface RecurringExpenseFieldValues {
  title: string;
  currency: string;
  amount: string;
  frequency: RecurringExpenseFrequency;
  categoryId: string;
  expires: boolean;
  validFrom: string;
  validUntil: string;
}

interface Props {
  query: RecurringExpenseForm_ExpenseCategoriesFragment;
  isQueryLoading: boolean;
  defaultValues?: RecurringExpenseInput;
  onSubmit: (value: RecurringExpenseInput) => void;
  isSaving?: boolean;
}

const FREQUENCY_OPTIONS = [
  {
    value: RecurringExpenseFrequency.Monthly,
    label: "Monthly",
  },
];

const TIMESTAMP_FORMAT = "yyyy-MM-dd";

const toFieldValues = (
  input: RecurringExpenseInput,
): RecurringExpenseFieldValues => ({
  title: input.title,
  currency: input.currency,
  amount: input.amount.toString(),
  frequency: input.frequency,
  categoryId: input.categoryId,
  expires: input.validUntil != null,
  validFrom: input.validFrom,
  validUntil: input.validUntil ?? "",
});

const fromValidatedFieldValues = (
  input: RecurringExpenseFieldValues,
): RecurringExpenseInput => ({
  title: input.title,
  currency: input.currency,
  amount: parseFloat(input.amount),
  frequency: input.frequency,
  categoryId: input.categoryId,
  validFrom: input.validFrom,
  validUntil: input.expires ? input.validUntil : null,
});

function RecurringExpenseForm({
  isQueryLoading,
  query,
  defaultValues,
  onSubmit,
  isSaving = false,
}: Props) {
  const NOW = useMemo(() => formatDate(new Date(), TIMESTAMP_FORMAT), []);

  const { register, handleSubmit, watch, errors } =
    useForm<RecurringExpenseFieldValues>({
      defaultValues:
        defaultValues != null
          ? toFieldValues(defaultValues)
          : {
              currency: "SGD",
              frequency: RecurringExpenseFrequency.Monthly,
              categoryId: "",
              validFrom: formatDate(
                add(new Date(), { days: 1 }),
                TIMESTAMP_FORMAT,
              ),
            },
    });
  const expires = watch("expires");
  const validFrom = watch("validFrom");

  const onFormSubmit = (input: RecurringExpenseFieldValues) => {
    onSubmit(fromValidatedFieldValues(input));
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <TextField
        use={errors.title != null ? "danger" : undefined}
        name="title"
        label="Title"
        ref={register({
          required: "Please enter the title of this recurring expense.",
        })}
        hint={errors.title?.message}
      />

      <CurrencySelector
        use={errors.currency != null ? "danger" : undefined}
        name="currency"
        label="Currency"
        ref={register({
          required: "Please enter the currency for this recurring expense.",
        })}
        hint={errors.currency?.message}
      />

      <TextField
        use={errors.amount != null ? "danger" : undefined}
        name="amount"
        label="Amount"
        ref={register({
          required: "Please enter the amount for this recurring expense.",
        })}
        type="number"
        step={0.01}
        hint={errors.amount?.message}
      />

      {isQueryLoading ? (
        <LoadingField label="Category" hint="Loading available categories" />
      ) : (
        <ExpenseCategorySelector
          use={errors.categoryId != null ? "danger" : undefined}
          query={query}
          name="categoryId"
          label="Category"
          ref={register({
            required: "Please select a category for this recurring expense.",
          })}
          hint={errors.categoryId?.message}
        />
      )}

      <Select
        use={errors.frequency != null ? "danger" : undefined}
        name="frequency"
        label="Frequency"
        options={FREQUENCY_OPTIONS}
        ref={register({
          required: "Please enter the frequency of this recurring expense.",
        })}
        hint={errors.frequency?.message}
      />

      <TextField
        use={errors.validFrom != null ? "danger" : undefined}
        name="validFrom"
        label="Valid From"
        type="date"
        ref={register({
          required:
            "Please enter when this recurring expense should come into effect.",
          validate(value) {
            return (
              value > NOW ||
              "A recurring expense cannot be created retroactively. Please select a date in the future."
            );
          },
        })}
        hint={errors.validFrom?.message}
      />

      <Checkbox name="expires" label="Expires?" ref={register} />

      {expires && (
        <TextField
          use={errors.validUntil != null ? "danger" : undefined}
          name="validUntil"
          label="Valid Until"
          type="date"
          ref={register({
            required: "Please enter when this recurring expense should expire.",
            validate(value) {
              if (validFrom.length === 0) {
                return true;
              }
              return (
                value >= validFrom ||
                "A recurring expense can expire only after it comes into affect."
              );
            },
          })}
          hint={errors.validUntil?.message}
        />
      )}

      <Button use="primary" isDisabled={isSaving} label="Save" />
    </form>
  );
}

export const RecurringExpenseFormFragment = gql`
  fragment RecurringExpenseForm_ExpenseCategories on Query {
    ...ExpenseCategorySelector_ExpenseCategories
  }
  ${ExpenseCategoriesFragment}
`;

export default RecurringExpenseForm;
