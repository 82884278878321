import React from "react";
import { useForm } from "react-hook-form";
import Button from "./Button";
import TextField from "./TextField";

const URL_PATTERN = /^https?:\/\//;

export interface BunnyCommandInput {
  trigger: string;
  url: string;
  urlTemplate: string | null | undefined;
}

interface Props {
  defaultValues?: BunnyCommandInput;
  onSubmit: (command: BunnyCommandInput) => void;
  isSaving?: boolean;
}

function BunnyCommandForm({
  isSaving = false,
  defaultValues,
  onSubmit,
}: Props) {
  const { register, handleSubmit, errors } = useForm<BunnyCommandInput>({
    defaultValues: defaultValues ?? {
      trigger: "",
      url: "",
      urlTemplate: null,
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        use={errors.trigger != null ? "danger" : undefined}
        type="text"
        name="trigger"
        label="Trigger"
        ref={register({
          required: "Please enter the trigger for this command.",
        })}
        hint={errors.trigger?.message}
      />

      <TextField
        use={errors.url != null ? "danger" : undefined}
        type="url"
        name="url"
        label="URL"
        ref={register({
          required: "Please enter the URL for this command.",
          pattern: {
            value: URL_PATTERN,
            message: "Please enter a valid URL.",
          },
        })}
        hint={errors.url?.message}
      />

      <TextField
        use={errors.urlTemplate != null ? "danger" : undefined}
        type="url"
        name="urlTemplate"
        label="URL Template"
        ref={register({
          pattern: {
            value: URL_PATTERN,
            message: "Please enter a valid URL.",
          },
        })}
        hint={errors.urlTemplate?.message}
      />

      <Button use="primary" isDisabled={isSaving} label="Save" />
    </form>
  );
}

export default BunnyCommandForm;
