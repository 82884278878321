import { useEffect, useRef } from "react";

function useDOMNode() {
  const nodeRef = useRef<HTMLDivElement>(document.createElement("div"));

  useEffect(() => {
    const node = nodeRef.current;
    if (node.parentElement == null) {
      const container = document.getElementById("portals");
      container?.appendChild(nodeRef.current);
    }

    return () => node.remove();
  }, []);

  return nodeRef.current;
}

export default useDOMNode;
