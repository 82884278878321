import React from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useNavigate } from "react-router-dom";
import Page from "./Page";
import RecurringExpenseForm, {
  RecurringExpenseInput,
  RecurringExpenseFormFragment,
} from "../components/RecurringExpenseForm";
import useEnsureSignedIn from "../hooks/use-ensure-signed-in";
import useNotifications from "../hooks/use-notifications";
import {
  NewRecurringExpensePageQuery,
  NewRecurringExpensePageQueryVariables,
  NewRecurringExpensePage_CreateRecurringExpenseMutation,
  NewRecurringExpensePage_CreateRecurringExpenseMutationVariables,
  NotificationFlashState,
  NotificationType,
} from "../generated/graphql";

const NEW_RECURRING_EXPENSE_PAGE_QUERY = gql`
  query NewRecurringExpensePage {
    ...RecurringExpenseForm_ExpenseCategories
  }
  ${RecurringExpenseFormFragment}
`;

const NEW_RECURRING_EXPENSE_PAGE_CREATE_RECURRING_EXPENSE_MUTATION = gql`
  mutation NewRecurringExpensePage_CreateRecurringExpense(
    $title: String!
    $currency: String!
    $amount: Float!
    $frequency: RecurringExpenseFrequency!
    $validFrom: String!
    $validUntil: String
    $categoryId: ID!
  ) {
    createRecurringExpense(
      title: $title
      currency: $currency
      amount: $amount
      frequency: $frequency
      validFrom: $validFrom
      validUntil: $validUntil
      categoryId: $categoryId
    ) {
      ... on ValidationErrors {
        errors {
          field
          messages
        }
      }
    }
  }
`;

function NewRecurringExpensePage() {
  useEnsureSignedIn();

  const navigate = useNavigate();

  const { addNotification } = useNotifications();

  const { data, loading } = useQuery<
    NewRecurringExpensePageQuery,
    NewRecurringExpensePageQueryVariables
  >(NEW_RECURRING_EXPENSE_PAGE_QUERY);

  const [createRecurringExpense, { loading: isSaving }] = useMutation<
    NewRecurringExpensePage_CreateRecurringExpenseMutation,
    NewRecurringExpensePage_CreateRecurringExpenseMutationVariables
  >(NEW_RECURRING_EXPENSE_PAGE_CREATE_RECURRING_EXPENSE_MUTATION);

  const onSubmit = async (input: RecurringExpenseInput) => {
    const { data, errors } = await createRecurringExpense({ variables: input });

    if (errors != null && errors.length > 0) {
      const messages = errors.map(error => error.message);
      alert(`GraphQL errors:\n${messages.join("\n")}`);
      return;
    }

    if (data == null) {
      alert("Empty response from GraphQL mutation.");
      return;
    }

    if (data.createRecurringExpense?.__typename === "ValidationErrors") {
      const errors = data.createRecurringExpense.errors;
      const messages = errors.flatMap(({ field, messages }) => {
        return messages.map(message => `${field} ${message}`);
      });
      alert(`Validation errors:\n${messages.join("\n")}`);
      return;
    }

    await addNotification(
      "Recurring expense created",
      NotificationFlashState.NextPage,
      NotificationType.Success,
    );
    navigate("/expenses/recurring");
  };

  return (
    <Page title="New Recurring Expense">
      <RecurringExpenseForm
        isQueryLoading={loading}
        query={data!}
        onSubmit={onSubmit}
        isSaving={isSaving}
      />
    </Page>
  );
}

export default NewRecurringExpensePage;
