import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import useNotifications from "../hooks/use-notifications";
import useEnsureSignedIn from "../hooks/use-ensure-signed-in";
import SimpleBudgetForm, {
  SimpleBudgetInput,
} from "../components/SimpleBudgetForm";
import Page from "./Page";
import {
  NotificationType,
  NotificationFlashState,
  EditSimpleBudgetPageQuery,
  EditSimpleBudgetPageQueryVariables,
  EditSimpleBudgetPage_UpdateBudgetMutation,
  EditSimpleBudgetPage_UpdateBudgetMutationVariables,
} from "../generated/graphql";

const EDIT_SIMPLE_BUDGET_PAGE_QUERY = gql`
  query EditSimpleBudgetPage($id: ID!) {
    expenseCategory(id: $id) {
      simpleBudget {
        currency
        amount
        period
      }
    }
  }
`;

const EDIT_SIMPLE_BUDGET_PAGE_UPDATE_BUDGET_MUTATION = gql`
  mutation EditSimpleBudgetPage_UpdateBudget(
    $currency: String!
    $amount: Float!
    $period: SimpleBudgetPeriod!
    $categoryId: ID!
  ) {
    createOrUpdateSimpleBudget(
      currency: $currency
      amount: $amount
      period: $period
      categoryId: $categoryId
    ) {
      ... on ValidationErrors {
        errors {
          field
          messages
        }
      }
    }
  }
`;

function EditSimpleBudgetPage() {
  useEnsureSignedIn();

  const params = useParams();
  const navigate = useNavigate();

  const { addNotification } = useNotifications();

  const { data } = useQuery<
    EditSimpleBudgetPageQuery,
    EditSimpleBudgetPageQueryVariables
  >(EDIT_SIMPLE_BUDGET_PAGE_QUERY, {
    variables: {
      id: params.id,
    },
  });

  const [saveBudget] = useMutation<
    EditSimpleBudgetPage_UpdateBudgetMutation,
    EditSimpleBudgetPage_UpdateBudgetMutationVariables
  >(EDIT_SIMPLE_BUDGET_PAGE_UPDATE_BUDGET_MUTATION);

  useEffect(() => {
    if (data != null && data.expenseCategory == null) {
      addNotification(
        "Expense category not found",
        NotificationFlashState.NextPage,
        NotificationType.Danger,
      );
      navigate("/expenses/categories");
    }
  }, [data, addNotification, navigate]);

  const onSubmit = async (simpleBudget: SimpleBudgetInput) => {
    const { data, errors } = await saveBudget({
      variables: {
        ...simpleBudget,
        categoryId: params.id,
      },
    });

    // TODO Handle this at link level
    if (errors != null && errors.length > 0) {
      const messages = errors.map(error => error.message);
      alert(`GraphQL errors:\n${messages.join("\n")}`);
      return;
    }

    if (data == null) {
      alert("Empty response from GraphQL mutation.");
      return;
    }

    if (data.createOrUpdateSimpleBudget?.__typename === "ValidationErrors") {
      const errors = data.createOrUpdateSimpleBudget.errors;
      const messages = errors.flatMap(({ field, messages }) => {
        return messages.map(message => `${field} ${message}`);
      });
      alert(`Validation errors:\n${messages.join("\n")}`);
      return;
    }

    await addNotification(
      "Budget updated",
      NotificationFlashState.NextPage,
      NotificationType.Success,
    );
    navigate("/expenses/categories");
  };

  if (data == null) {
    return <div>Loading...</div>;
  }

  return (
    <Page title="Edit Simple Budget">
      <SimpleBudgetForm
        defaultValues={data.expenseCategory?.simpleBudget ?? undefined}
        onSubmit={onSubmit}
      />
    </Page>
  );
}

export default EditSimpleBudgetPage;
