import React from "react";
import gql from "graphql-tag";
import InterestedBooksListItemPriceComparisonCell, {
  InterestedBooksListItemPriceComparisonCellFragment,
} from "./InterestedBooksListItemPriceComparisonCell";
import { InterestedBooksListItemOneWeekPriceComparisonCellFragment_InterestedBookFragment } from "../generated/graphql";

interface Props {
  book: InterestedBooksListItemOneWeekPriceComparisonCellFragment_InterestedBookFragment;
}

function InterestedBooksListItemOneWeekPriceComparisonCell({ book }: Props) {
  return (
    <InterestedBooksListItemPriceComparisonCell
      label="1-week ∆"
      latestPrice={book.latestPrice}
      referencePrice={book.priceOneWeekAgo}
    />
  );
}

export const InterestedBooksListItemOneWeekPriceComparisonCellFragment = gql`
  fragment InterestedBooksListItemOneWeekPriceComparisonCellFragment_InterestedBook on InterestedBook {
    latestPrice: price {
      ...InterestedBooksListItemPriceComparisonCellFragment_LatestPrice
    }
    priceOneWeekAgo: price(date: $oneWeekAgo) {
      ...InterestedBooksListItemPriceComparisonCellFragment_ReferencePrice
    }
  }
  ${InterestedBooksListItemPriceComparisonCellFragment}
`;

export default InterestedBooksListItemOneWeekPriceComparisonCell;
