import React from "react";
import gql from "graphql-tag";
import ExpenseReportBudgetItem, {
  ExpenseReportBudgetItemFragment,
} from "./ExpenseReportBudgetItem";
import { ExpenseReportBudgetSection_ExpenseReportFragment } from "../generated/graphql";

interface Props {
  expenseReport: ExpenseReportBudgetSection_ExpenseReportFragment;
}

function ExpenseReportBudgetSection({ expenseReport }: Props) {
  return (
    <div className="message">
      <div className="message-header">Budgets</div>
      <div className="message-body">
        {expenseReport.byCategory.map(categoricalExpenseReport => (
          <ExpenseReportBudgetItem
            key={categoricalExpenseReport.category.id}
            categoricalExpenseReport={categoricalExpenseReport}
          />
        ))}
      </div>
    </div>
  );
}

export const ExpenseReportBudgetSectionFragment = gql`
  fragment ExpenseReportBudgetSection_ExpenseReport on ExpenseReport {
    byCategory {
      category {
        id
      }
      ...ExpenseReportBudgetItem_CategoricalExpenseReport
    }
  }
  ${ExpenseReportBudgetItemFragment}
`;

export default ExpenseReportBudgetSection;
