import React, { useContext } from "react";
import nullthrows from "../utils/nullthrows";

export interface ExpenseReportMetadata {
  from: string;
  to: string;
  currency: string;
}

const ExpenseReportMetadataContext =
  React.createContext<ExpenseReportMetadata | null>(null);
const { Provider } = ExpenseReportMetadataContext;

function useExpenseReportMetadata(): ExpenseReportMetadata {
  const metadata = useContext(ExpenseReportMetadataContext);
  return nullthrows(metadata);
}

export { Provider, useExpenseReportMetadata };
