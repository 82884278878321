import React from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { useNavigate } from "react-router-dom";
import Page from "./Page";
import InterestedBookForm, {
  InterestedBookInput,
} from "../components/InterestedBookForm";
import useNotifications from "../hooks/use-notifications";
import {
  NewInterestedBookPage_CreateInterestedBookMutation,
  NewInterestedBookPage_CreateInterestedBookMutationVariables,
  NotificationFlashState,
  NotificationType,
} from "../generated/graphql";

const NEW_INTERESTED_BOOK_PAGE_CREATE_INTERESTED_BOOK_MUTATION = gql`
  mutation NewInterestedBookPage_CreateInterestedBook(
    $title: String!
    $url: String!
  ) {
    createInterestedBook(title: $title, url: $url) {
      ... on ValidationErrors {
        errors {
          field
          messages
        }
      }
    }
  }
`;

function NewInterestedBookPage() {
  const navigate = useNavigate();

  const { addNotification } = useNotifications();

  const [createInterestedBook, { loading: isSaving }] = useMutation<
    NewInterestedBookPage_CreateInterestedBookMutation,
    NewInterestedBookPage_CreateInterestedBookMutationVariables
  >(NEW_INTERESTED_BOOK_PAGE_CREATE_INTERESTED_BOOK_MUTATION);

  const onSubmit = async (interestedBook: InterestedBookInput) => {
    const { data, errors } = await createInterestedBook({
      variables: interestedBook,
    });

    // TODO Handle this at link level
    if (errors != null && errors.length > 0) {
      const messages = errors.map(error => error.message);
      alert(`GraphQL errors:\n${messages.join("\n")}`);
      return;
    }

    if (data == null) {
      alert("Empty response from GraphQL mutation.");
      return;
    }

    if (data.createInterestedBook?.__typename === "ValidationErrors") {
      const errors = data.createInterestedBook.errors;
      const messages = errors.flatMap(({ field, messages }) => {
        return messages.map(message => `${field} ${message}`);
      });
      alert(`Validation errors:\n${messages.join("\n")}`);
      return;
    }

    await addNotification(
      "Interested book created",
      NotificationFlashState.NextPage,
      NotificationType.Success,
    );
    navigate("/interested_books");
  };

  return (
    <Page title="New Interested Book">
      <InterestedBookForm isSaving={isSaving} onSubmit={onSubmit} />
    </Page>
  );
}

export default NewInterestedBookPage;
