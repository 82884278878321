import { get, set } from "./base-storage";

const STORAGE_KEY = "SESSION_TOKEN";

export function getSessionToken(): string | null {
  return get(STORAGE_KEY);
}

export function setSessionToken(token: string | null): void {
  set(STORAGE_KEY, token);
}
