import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import nullthrows from "../utils/nullthrows";
import {
  UseNotifications_AddNotificationMutation,
  UseNotifications_AddNotificationMutationVariables,
  UseNotifications_RemoveNotificationMutation,
  UseNotifications_RemoveNotificationMutationVariables,
  NotificationType,
  NotificationFlashState,
} from "../generated/graphql";

type AddNotificationFn = (
  message: string,
  flashState: NotificationFlashState,
  type?: NotificationType | null,
) => Promise<string>;
type RemoveNotificationFn = (id: string) => Promise<boolean>;

interface Output {
  addNotification: AddNotificationFn;
  removeNotification: RemoveNotificationFn;
}

const USE_NOTIFICATIONS_ADD_NOTIFICATION_MUTATION = gql`
  mutation UseNotifications_AddNotification(
    $message: String!
    $flashState: NotificationFlashState!
    $type: NotificationType
  ) {
    addNotification(message: $message, flashState: $flashState, type: $type)
      @client {
      id
    }
  }
`;

const USE_NOTIFICATIONS_REMOVE_NOTIFICATION_MUTATION = gql`
  mutation UseNotifications_RemoveNotification($id: ID!) {
    removeNotification(id: $id) @client {
      __typename
    }
  }
`;

function useNotifications(): Output {
  const [addNotificationMutation] = useMutation<
    UseNotifications_AddNotificationMutation,
    UseNotifications_AddNotificationMutationVariables
  >(USE_NOTIFICATIONS_ADD_NOTIFICATION_MUTATION);

  const [removeNotificationMutation] = useMutation<
    UseNotifications_RemoveNotificationMutation,
    UseNotifications_RemoveNotificationMutationVariables
  >(USE_NOTIFICATIONS_REMOVE_NOTIFICATION_MUTATION);

  const addNotification = async (
    message: string,
    flashState: NotificationFlashState,
    type: NotificationType | null = null,
  ): Promise<string> => {
    const { data } = await addNotificationMutation({
      variables: { message, flashState, type },
    });

    return nullthrows(data).addNotification.id;
  };

  const removeNotification = async (id: string): Promise<boolean> => {
    const { data } = await removeNotificationMutation({
      variables: { id },
    });

    return nullthrows(data).removeNotification != null;
  };

  return { addNotification, removeNotification };
}

export default useNotifications;
