import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useNavigate } from "react-router-dom";
import Page from "./Page";
import ExpenseForm, {
  ExpenseInput,
  ExpenseFormFragment,
} from "../components/ExpenseForm";
import useEnsureSignedIn from "../hooks/use-ensure-signed-in";
import useNotifications from "../hooks/use-notifications";
import {
  NewExpensePageQuery,
  NewExpensePageQueryVariables,
  NewExpensePage_CreateExpenseMutation,
  NewExpensePage_CreateExpenseMutationVariables,
  NotificationFlashState,
  NotificationType,
} from "../generated/graphql";

const NEW_EXPENSE_PAGE_QUERY = gql`
  query NewExpensePage {
    ...ExpenseForm_ExpenseCategories
  }
  ${ExpenseFormFragment}
`;

const NEW_EXPENSE_PAGE_CREATE_EXPENSE_MUTATION = gql`
  mutation NewExpensePage_CreateExpense(
    $currency: String!
    $amount: Float!
    $categoryId: ID!
    $description: String
    $timestamp: Int!
  ) {
    createExpense(
      currency: $currency
      amount: $amount
      categoryId: $categoryId
      description: $description
      timestamp: $timestamp
    ) {
      ... on ValidationErrors {
        errors {
          field
          messages
        }
      }
    }
  }
`;

function NewExpensePage() {
  useEnsureSignedIn();

  const navigate = useNavigate();

  const { addNotification } = useNotifications();

  const { data, loading } = useQuery<
    NewExpensePageQuery,
    NewExpensePageQueryVariables
  >(NEW_EXPENSE_PAGE_QUERY);

  const [createExpense, { loading: isSaving }] = useMutation<
    NewExpensePage_CreateExpenseMutation,
    NewExpensePage_CreateExpenseMutationVariables
  >(NEW_EXPENSE_PAGE_CREATE_EXPENSE_MUTATION);

  const onSubmit = async (input: ExpenseInput) => {
    const { data, errors } = await createExpense({
      variables: {
        ...input,
        timestamp: Math.floor(input.timestamp / 1000),
      },
    });

    // TODO Handle this at link level
    if (errors != null && errors.length > 0) {
      const messages = errors.map(error => error.message);
      alert(`GraphQL errors:\n${messages.join("\n")}`);
      return;
    }

    if (data == null) {
      alert("Empty response from GraphQL mutation.");
      return;
    }

    if (data.createExpense?.__typename === "ValidationErrors") {
      const errors = data.createExpense.errors;
      const messages = errors.flatMap(({ field, messages }) => {
        return messages.map(message => `${field} ${message}`);
      });
      alert(`Validation errors:\n${messages.join("\n")}`);
      return;
    }

    await addNotification(
      "Expense created",
      NotificationFlashState.NextPage,
      NotificationType.Success,
    );
    navigate("/expenses");
  };

  return (
    <Page title="New Expense">
      <ExpenseForm
        query={data!}
        isQueryLoading={loading}
        onSubmit={onSubmit}
        isSaving={isSaving}
      />
    </Page>
  );
}

export default NewExpensePage;
