import React from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { useNavigate } from "react-router-dom";
import useNotifications from "../hooks/use-notifications";
import useEnsureSignedIn from "../hooks/use-ensure-signed-in";
import Page from "./Page";
import ExpenseCategoryForm, {
  ExpenseCategoryInput,
} from "../components/ExpenseCategoryForm";
import {
  NotificationType,
  NotificationFlashState,
  NewExpenseCategoryPage_CreateExpenseCategoryMutation,
  NewExpenseCategoryPage_CreateExpenseCategoryMutationVariables,
} from "../generated/graphql";

const NEW_EXPENSE_CATEGORY_PAGE_MUTATION = gql`
  mutation NewExpenseCategoryPage_CreateExpenseCategory(
    $title: String!
    $icon: String!
  ) {
    createExpenseCategory(title: $title, icon: $icon) {
      ... on ValidationErrors {
        errors {
          field
          messages
        }
      }
    }
  }
`;

function NewExpenseCategoryPage() {
  useEnsureSignedIn();

  const navigate = useNavigate();

  const { addNotification } = useNotifications();

  const [createExpenseCategory, { loading: isSaving }] = useMutation<
    NewExpenseCategoryPage_CreateExpenseCategoryMutation,
    NewExpenseCategoryPage_CreateExpenseCategoryMutationVariables
  >(NEW_EXPENSE_CATEGORY_PAGE_MUTATION);

  const onSubmit = async (expenseCategory: ExpenseCategoryInput) => {
    const { data, errors } = await createExpenseCategory({
      variables: expenseCategory,
    });

    // TODO Handle this at link level
    if (errors != null && errors.length > 0) {
      const messages = errors.map(error => error.message);
      alert(`GraphQL errors:\n${messages.join("\n")}`);
      return;
    }

    if (data == null) {
      alert("Empty response from GraphQL mutation.");
      return;
    }

    if (data.createExpenseCategory?.__typename === "ValidationErrors") {
      const errors = data.createExpenseCategory.errors;
      const messages = errors.flatMap(({ field, messages }) => {
        return messages.map(message => `${field} ${message}`);
      });
      alert(`Validation errors:\n${messages.join("\n")}`);
      return;
    }

    await addNotification(
      "Expense category created",
      NotificationFlashState.NextPage,
      NotificationType.Success,
    );
    navigate("/expenses/categories");
  };

  return (
    <Page title="New Expense Category">
      <ExpenseCategoryForm isSaving={isSaving} onSubmit={onSubmit} />
    </Page>
  );
}

export default NewExpenseCategoryPage;
