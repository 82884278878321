import React, { ReactChild } from "react";
import classNames from "classnames";

export type NotificationUse =
  | "primary"
  | "link"
  | "info"
  | "success"
  | "warning"
  | "danger";

interface Props {
  use?: NotificationUse;
  children: ReactChild;
  onClose?: () => void;
}

function Notification({ use, children, onClose }: Props) {
  return (
    <div
      className={classNames("notification", {
        [`is-${use}`]: use !== undefined,
      })}
    >
      {onClose != null && <button className="delete" onClick={onClose} />}
      {children}
    </div>
  );
}

export default Notification;
