import React from "react";
import { useForm } from "react-hook-form";
import Button from "./Button";
import TextField from "./TextField";

export interface BunnyProfileInput {
  name: string;
}

interface Props {
  defaultValues?: BunnyProfileInput;
  onSubmit: (command: BunnyProfileInput) => void;
  isSaving?: boolean;
}

function BunnyProfileForm({
  isSaving = false,
  onSubmit,
  defaultValues,
}: Props) {
  const { register, handleSubmit, errors } = useForm<BunnyProfileInput>({
    defaultValues: defaultValues ?? { name: "" },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        use={errors.name != null ? "danger" : undefined}
        type="text"
        name="name"
        label="Name"
        ref={register({
          required: "Please enter the name for this profile.",
        })}
        hint={errors.name?.message}
      />

      <Button use="primary" isDisabled={isSaving} label="Save" />
    </form>
  );
}

export default BunnyProfileForm;
