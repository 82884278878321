import { useRef, useEffect, useMemo } from "react";
import Chart from "chart.js";

type Register = (instance: HTMLCanvasElement | null) => void;

interface OutputType {
  register: Register;
  chart: Chart | undefined;
}

function useChart(options: Chart.ChartConfiguration): OutputType {
  const canvas = useRef<HTMLCanvasElement | null>();
  const chart = useRef<Chart>();

  useEffect(() => {
    chart.current = new Chart(canvas.current!, options);

    return () => {
      chart.current?.destroy();
    };

    // The create and update effects are different. This is the
    // effect to create the Chart instance, so it should run
    // exactly once, hence the deps array is empty.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    chart.current!.config = options;
    chart.current!.update();
  }, [options]);

  const register = useMemo(
    (): Register => instance => {
      canvas.current = instance;
    },
    [],
  );

  return { register, chart: chart.current! };
}

export default useChart;
