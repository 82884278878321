import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import useNotifications from "../hooks/use-notifications";
import useEnsureSignedIn from "../hooks/use-ensure-signed-in";
import Page from "./Page";
import BookPriceChart from "../components/BookPriceChart";
import {
  NotificationType,
  NotificationFlashState,
  InterestedBookDetailsQuery,
  InterestedBookDetailsQueryVariables,
} from "../generated/graphql";

const INTERESTED_BOOK_DETAILS_PAGE_QUERY = gql`
  query InterestedBookDetails($id: ID!) {
    interestedBook(id: $id) {
      title
      url
      prices {
        date
        price
      }
    }
  }
`;

function InterestedBookDetailsPage() {
  useEnsureSignedIn();

  const { id } = useParams();
  const navigate = useNavigate();

  const { addNotification } = useNotifications();

  const { data } = useQuery<
    InterestedBookDetailsQuery,
    InterestedBookDetailsQueryVariables
  >(INTERESTED_BOOK_DETAILS_PAGE_QUERY, { variables: { id } });

  useEffect(() => {
    if (data != null && data.interestedBook == null) {
      addNotification(
        "Book not found",
        NotificationFlashState.NextPage,
        NotificationType.Danger,
      );
      navigate("/interested_books");
    }
  }, [data, addNotification, navigate]);

  if (data == null) {
    return <div>Loading...</div>;
  }

  const { interestedBook } = data;

  return (
    <Page title="Interested Book">
      <div className="message">
        <div className="message-header">{interestedBook?.title}</div>
        <div className="message-body">
          <BookPriceChart prices={interestedBook?.prices ?? []} />
          <p>
            View on{" "}
            <a
              href={interestedBook?.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Book Depository
            </a>
            .
          </p>
        </div>
      </div>
    </Page>
  );
}

export default InterestedBookDetailsPage;
