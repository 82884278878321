import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { Link } from "react-router-dom";
import useEnsureSignedIn from "../hooks/use-ensure-signed-in";
import Page from "./Page";
import DynamicIcon from "../components/DynamicIcon";
import {
  ExpenseCategoriesListPageQuery,
  ExpenseCategoriesListPageQueryVariables,
} from "../generated/graphql";
import styles from "./ExpenseCategoriesList.module.css";

const EXPENSE_CATEGORIES_LIST_PAGE_QUERY = gql`
  query ExpenseCategoriesListPage {
    expenseCategories {
      id
      title
      icon
    }
  }
`;

function ExpenseCategoriesListPage() {
  useEnsureSignedIn();

  const { data } = useQuery<
    ExpenseCategoriesListPageQuery,
    ExpenseCategoriesListPageQueryVariables
  >(EXPENSE_CATEGORIES_LIST_PAGE_QUERY, {
    fetchPolicy: "cache-and-network",
    partialRefetch: true,
  });

  if (data == null) {
    return <div>Loading...</div>;
  }

  return (
    <Page
      title="Expense Categories"
      actionButton={<Link to="/expenses/categories/new">Add</Link>}
    >
      <ul>
        {data.expenseCategories.map(({ id, title, icon }) => (
          <li key={id}>
            <DynamicIcon icon={icon} containerClassName={styles.icon} />
            {title} (
            <Link to={`/expenses/categories/${id}/budget`}>Update budget</Link>)
          </li>
        ))}
      </ul>
    </Page>
  );
}

export default ExpenseCategoriesListPage;
