import React from "react";
import { Link } from "react-router-dom";
import Page from "./Page";
import useEnsureSignedIn from "../hooks/use-ensure-signed-in";

function IndexPage() {
  useEnsureSignedIn();
  return (
    <Page>
      <Link to="/expenses" className="button">
        Expenses
      </Link>
      <Link to="/interested_books" className="button">
        Books
      </Link>
      <Link to="/bunny/profiles" className="button">
        Bunny
      </Link>
      <Link to="/services" className="button">
        Services
      </Link>
    </Page>
  );
}

export default IndexPage;
