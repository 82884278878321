// All local storage operations must go through
// this module. It takes care of things like
// memoization.
// Perhaps can consider doing things like LRU
// to reduce memory pressure.

const cache = new Map<string, string | null>();

export function get(key: string): string | null {
  if (cache.has(key)) {
    return cache.get(key) as string | null;
  }

  const value = localStorage.getItem(key);
  cache.set(key, value);
  return value;
}

export function set(key: string, value: string | null): void {
  cache.set(key, value);
  if (value == null) {
    localStorage.removeItem(key);
  } else {
    localStorage.setItem(key, value);
  }
}
