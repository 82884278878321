import gql from "graphql-tag";
import { Context } from "./context-type";
import {
  UpdateNotificationsFlashStateResolverQuery,
  UpdateNotificationsFlashStateResolverQueryVariables,
  Notification,
  NotificationFlashState,
} from "../generated/graphql";

const UPDATE_NOTIFICATIONS_FLASH_STATE_RESOLVER = gql`
  query UpdateNotificationsFlashStateResolver {
    notifications {
      id
      message
      flashState
      type
    }
  }
`;

function updateNotificationsFlashState(
  _parent: any,
  _args: any,
  { client }: Context,
): Notification[] {
  const data = client.readQuery<
    UpdateNotificationsFlashStateResolverQuery,
    UpdateNotificationsFlashStateResolverQueryVariables
  >({ query: UPDATE_NOTIFICATIONS_FLASH_STATE_RESOLVER });

  const notifications = data?.notifications ?? [];

  const isUpdateNeeded = notifications.some(
    ({ flashState }) =>
      flashState === NotificationFlashState.CurrentPage ||
      flashState === NotificationFlashState.NextPage,
  );

  if (!isUpdateNeeded) {
    return notifications;
  }

  const updatedNotifications = notifications
    .filter(
      ({ flashState }) => flashState !== NotificationFlashState.CurrentPage,
    )
    .map(notification => {
      if (notification.flashState === NotificationFlashState.NextPage) {
        return {
          ...notification,
          flashState: NotificationFlashState.CurrentPage,
        };
      }

      return notification;
    });

  client.writeData({
    data: {
      notifications: updatedNotifications,
    },
  });

  return updatedNotifications;
}

export default updateNotificationsFlashState;
