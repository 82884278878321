import React, { useState, useCallback } from "react";
import ReactDOM from "react-dom";
import Button, { Props as ButtonProps } from "./Button";
import Modal from "./Modal";
import useDOMNode from "../hooks/use-dom-node";

type ExposedButtonProps = Pick<ButtonProps, "use" | "label" | "isDisabled">;

interface Props extends ExposedButtonProps {
  children: React.ReactNode;
}

function ModalLauncherButton({ children, ...buttonProps }: Props) {
  const node = useDOMNode();
  const [isShown, setIsShown] = useState(false);
  const onShow = useCallback(() => setIsShown(true), [setIsShown]);
  const onHide = useCallback(() => setIsShown(false), [setIsShown]);

  return (
    <div>
      <Button
        use={buttonProps.use}
        label={buttonProps.label}
        isDisabled={isShown || buttonProps.isDisabled}
        onClick={onShow}
      />
      {ReactDOM.createPortal(
        <Modal isShown={isShown} onHide={onHide}>
          {children}
        </Modal>,
        node,
      )}
    </div>
  );
}

export default ModalLauncherButton;
