import gql from "graphql-tag";
import { Context } from "./context-type";
import {
  MutationRemoveNotificationArgs,
  RemoveNotificationResolverQuery,
  RemoveNotificationResolverQueryVariables,
  Notification,
} from "../generated/graphql";

const REMOVE_NOTIFICATION_RESOLVER_QUERY = gql`
  query RemoveNotificationResolver {
    notifications @client {
      id
      message
      flashState
      type
    }
  }
`;

function removeNotification(
  _: any,
  { id }: MutationRemoveNotificationArgs,
  { client }: Context,
): Notification | null {
  const data = client.readQuery<
    RemoveNotificationResolverQuery,
    RemoveNotificationResolverQueryVariables
  >({ query: REMOVE_NOTIFICATION_RESOLVER_QUERY });

  const notifications = data?.notifications ?? [];
  const targetNotification = notifications.find(
    notification => notification.id === id,
  );

  if (targetNotification != null) {
    client.writeData({
      data: {
        notifications: notifications.filter(
          notification => notification.id !== id,
        ),
      },
    });
  }

  return targetNotification ?? null;
}

export default removeNotification;
