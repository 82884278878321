import React, { useContext } from "react";

type AppMenuVisibility = [boolean, (isVisible: boolean) => void];

const AppMenuVisibilityContext = React.createContext<AppMenuVisibility>([
  false,
  () => {},
]);

const { Provider } = AppMenuVisibilityContext;

function useAppMenuVisibility(): AppMenuVisibility {
  return useContext(AppMenuVisibilityContext);
}

export { Provider, useAppMenuVisibility };
