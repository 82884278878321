import React from "react";
import gql from "graphql-tag";
import InterestedBooksListItemPriceComparisonCell, {
  InterestedBooksListItemPriceComparisonCellFragment,
} from "./InterestedBooksListItemPriceComparisonCell";
import { InterestedBooksListItemOneMonthPriceComparisonCellFragment_InterestedBookFragment } from "../generated/graphql";

interface Props {
  book: InterestedBooksListItemOneMonthPriceComparisonCellFragment_InterestedBookFragment;
}

function InterestedBooksListItemOneMonthPriceComparisonCell({ book }: Props) {
  return (
    <InterestedBooksListItemPriceComparisonCell
      label="1-month ∆"
      latestPrice={book.latestPrice}
      referencePrice={book.priceOneMonthAgo}
    />
  );
}

export const InterestedBooksListItemOneMonthPriceComparisonCellFragment = gql`
  fragment InterestedBooksListItemOneMonthPriceComparisonCellFragment_InterestedBook on InterestedBook {
    latestPrice: price {
      ...InterestedBooksListItemPriceComparisonCellFragment_LatestPrice
    }
    priceOneMonthAgo: price(date: $oneMonthAgo) {
      ...InterestedBooksListItemPriceComparisonCellFragment_ReferencePrice
    }
  }
  ${InterestedBooksListItemPriceComparisonCellFragment}
`;

export default InterestedBooksListItemOneMonthPriceComparisonCell;
