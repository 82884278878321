import React, { useState, useEffect, ReactNode } from "react";
import NavigationBar from "../components/NavigationBar";
import ApplicationMenu from "../components/ApplicationMenu";
import useApplicationMenu from "../hooks/use-application-menu";
import { Provider as AppMenuVisibilityProvider } from "../context/app-menu-visibility";
import styles from "./Page.module.css";

interface Props {
  children: ReactNode;
  title?: string;
  actionButton?: ReactNode;
}

function Page({ title, children, actionButton }: Props) {
  const menuItems = useApplicationMenu();
  const [showAppMenu, setShowAppMenu] = useState(false);

  useEffect(() => {
    if (showAppMenu && menuItems.length === 0) {
      setShowAppMenu(false);
    }
  }, [showAppMenu, menuItems.length]);

  const onToggleMenu = () => setShowAppMenu(!showAppMenu);

  return (
    <AppMenuVisibilityProvider value={[showAppMenu, setShowAppMenu]}>
      <div className={styles.container}>
        <NavigationBar
          title={title}
          actionButton={actionButton}
          onToggleMenu={menuItems.length > 0 ? onToggleMenu : undefined}
        />
        <div className={styles.contentContainer}>
          {showAppMenu && (
            <div className={styles.appMenuContainer}>
              <ApplicationMenu menuItems={menuItems} />
            </div>
          )}
          <div className={styles.contentArea}>{children}</div>
        </div>
      </div>
    </AppMenuVisibilityProvider>
  );
}

export default Page;
