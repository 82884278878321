import React from "react";
import { useForm } from "react-hook-form";
import TextField from "./TextField";
import Button from "./Button";

export interface InterestedBookInput {
  title: string;
  url: string;
}

const URL_PATTERN = /^https?:\/\/www\.bookdepository\.com/;

function validateURL(url: string) {
  return URL_PATTERN.test(url) || "Please enter a URL from Book Depository.";
}

interface Props {
  onSubmit: (interestedBook: InterestedBookInput) => void;
  isSaving?: boolean;
}

function InterestedBookForm({ onSubmit, isSaving }: Props) {
  const { register, handleSubmit, errors } = useForm<InterestedBookInput>();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        use={errors.title != null ? "danger" : undefined}
        name="title"
        label="Title"
        ref={register({
          required: "Please enter a title for this book.",
        })}
        hint={errors.title?.message}
      />

      <TextField
        use={errors.url != null ? "danger" : undefined}
        name="url"
        label="URL"
        ref={register({
          required: "Please enter a URL for this book.",
          validate: validateURL,
        })}
        hint={errors.url?.message}
      />

      <Button use="primary" isDisabled={isSaving} label="Save" />
    </form>
  );
}

export default InterestedBookForm;
