import React from "react";
import { useParams } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { produce } from "immer";
import { Link } from "react-router-dom";
import Page from "./Page";
import useEnsureSignedIn from "../hooks/use-ensure-signed-in";
import ExpenseReport, {
  ExpenseReportFragment,
} from "../components/ExpenseReport";
import {
  MonthlyExpenseReportPageQuery,
  MonthlyExpenseReportPageQueryVariables,
} from "../generated/graphql";

const MONTHLY_EXPENSE_REPORT_PAGE_QUERY = gql`
  query MonthlyExpenseReportPage(
    $year: Int!
    $month: Int!
    $currency: String!
    $after: String
  ) {
    monthlyExpenseReport(year: $year, month: $month, currency: $currency) {
      ...ExpenseReport_ExpenseReport
      expenses(after: $after) {
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${ExpenseReportFragment}
`;

function MonthlyExpenseReportPage() {
  useEnsureSignedIn();

  const params = useParams();
  const year = parseInt(params.year);
  const month = parseInt(params.month);

  const { data, fetchMore, loading } = useQuery<
    MonthlyExpenseReportPageQuery,
    MonthlyExpenseReportPageQueryVariables
  >(MONTHLY_EXPENSE_REPORT_PAGE_QUERY, {
    variables: {
      year,
      month,
      currency: "SGD",
    },
    fetchPolicy: "cache-and-network",
    partialRefetch: true,
  });

  if (data == null) {
    return <div>Loading...</div>;
  }

  const loadMore = () => {
    fetchMore({
      variables: {
        after: data.monthlyExpenseReport.expenses.pageInfo.endCursor,
      },
      updateQuery(previousResult, { fetchMoreResult }) {
        if (fetchMoreResult == null) {
          return previousResult;
        }

        return produce(previousResult, result => {
          result.monthlyExpenseReport.expenses.nodes = [
            ...(previousResult.monthlyExpenseReport.expenses.nodes || []),
            ...(fetchMoreResult.monthlyExpenseReport.expenses.nodes || []),
          ];
          result.monthlyExpenseReport.expenses.pageInfo =
            fetchMoreResult.monthlyExpenseReport.expenses.pageInfo;
        });
      },
    });
  };

  return (
    <Page
      title="Monthly Expenses"
      actionButton={<Link to="/expenses/new">Add</Link>}
    >
      <ExpenseReport
        expenseReport={data.monthlyExpenseReport}
        isLoadingMore={loading}
        loadMore={
          data.monthlyExpenseReport.expenses.pageInfo.hasNextPage
            ? loadMore
            : undefined
        }
      />
    </Page>
  );
}

export default MonthlyExpenseReportPage;
