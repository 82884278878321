import React, { useMemo, useEffect } from "react";
import gql from "graphql-tag";
import Chart from "chart.js";
import useChart from "../hooks/use-chart";
import DEFAULT_COLOR_PALETTE from "../configs/default-color-palette";
import { useAppMenuVisibility } from "../context/app-menu-visibility";
import { ExpenseBreakdownPieChart_ExpenseReportFragment } from "../generated/graphql";

interface Props {
  expenseReport: ExpenseBreakdownPieChart_ExpenseReportFragment;
}

function ExpenseBreakdownPieChart({ expenseReport }: Props) {
  const options = useMemo((): Chart.ChartConfiguration => {
    const labels = expenseReport.byCategory.map(
      ({ category }) => category.title,
    );
    const data = expenseReport.byCategory.map(({ total }) => total);

    const currency = expenseReport.currency;

    return {
      type: "pie",
      options: {
        legend: {
          position: "bottom",
        },
        tooltips: {
          callbacks: {
            label(tooltipItem, data) {
              const label = data.labels![tooltipItem.index!];
              const value = data.datasets![tooltipItem.datasetIndex!].data![
                tooltipItem.index!
              ] as number;

              return `${label}: ${currency} ${value.toFixed(2)}`;
            },
          },
        },
      },
      data: {
        labels,
        datasets: [
          {
            data,
            backgroundColor: DEFAULT_COLOR_PALETTE,
          },
        ],
      },
    };
  }, [expenseReport]);
  const { register, chart } = useChart(options);

  const [isAppMenuVisible] = useAppMenuVisibility();

  useEffect(() => {
    // By right the library handles resizes, but somehow it doesn't
    // work when the app menu toggles.
    chart?.resize();
  }, [chart, isAppMenuVisible]);

  return <canvas ref={register} />;
}

export const ExpenseBreakdownPieChartFragment = gql`
  fragment ExpenseBreakdownPieChart_ExpenseReport on ExpenseReport {
    currency
    byCategory {
      total
      category {
        title
      }
    }
  }
`;

export default ExpenseBreakdownPieChart;
