import React from "react";
import gql from "graphql-tag";
import { Link } from "react-router-dom";
import { InterestedBooksListItemTitleCellFragment_InterestedBookFragment } from "../generated/graphql";
import styles from "./InterestedBooksList.module.css";

interface Props {
  book: InterestedBooksListItemTitleCellFragment_InterestedBookFragment;
}

function InterestedBooksListItemTitleCell({ book }: Props) {
  return (
    <Link to={`/interested_books/${book.id}`} className={styles.titleCell}>
      {book.title}
    </Link>
  );
}

export const InterestedBooksListItemTitleCellFragment = gql`
  fragment InterestedBooksListItemTitleCellFragment_InterestedBook on InterestedBook {
    id
    title
  }
`;

export default InterestedBooksListItemTitleCell;
