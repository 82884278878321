import React from "react";
import { useForm, useFieldArray } from "react-hook-form";
import TextField from "./TextField";
import Button from "./Button";
import styles from "./EbooksSetupForm.module.css";

// There is a break in naming convention of the types with
// the rest of the app. So don't take this too seriously.

// EbookInput and FormInput are the shape of defaultValues
// which allow notionPageId to be nullable.
interface EbookInput {
  id: string;
  title: string;
  notionPageId: string | null | undefined;
}

interface FormInput {
  ebooks: Array<EbookInput>;
}

interface ValidatedEbookValues {
  id: string;
  title: string;
  notionPageId: string;
}

export interface ValidatedFormValues {
  ebooks: Array<ValidatedEbookValues>;
}

interface Props {
  // Required because we don't allow user to create new value here
  defaultValues: FormInput;
  onSubmit: (formValues: ValidatedFormValues) => void;
  isSaving?: boolean;
}

const toValidatedFormValues = (input: FormInput): ValidatedFormValues => ({
  ebooks: input.ebooks.map(ebook => ({
    id: ebook.id,
    title: ebook.title,
    notionPageId: ebook.notionPageId!,
  })),
});

function EbooksSetupForm({ defaultValues, onSubmit, isSaving = false }: Props) {
  const { register, control, handleSubmit, errors } = useForm<FormInput>({
    defaultValues,
  });
  const { fields } = useFieldArray<FormInput>({
    control,
    name: "ebooks",
  });

  const onFormSubmit = (input: FormInput) =>
    onSubmit(toValidatedFormValues(input));

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      {fields.map((ebook, index) => (
        <div key={ebook.id} className={styles.ebookContainer}>
          <TextField
            label="ID"
            name={`ebooks[${index}].id`}
            type="hidden"
            ref={register()}
          />
          <TextField
            label="Title"
            name={`ebooks[${index}].title`}
            isDisabled={true}
            ref={register()}
          />
          <TextField
            use={
              errors.ebooks?.[index]?.notionPageId != null
                ? "danger"
                : undefined
            }
            label="Notion Page ID"
            name={`ebooks[${index}].notionPageId`}
            ref={register({
              required: "Please enter the Notion page ID for this e-book.",
            })}
            hint={errors.ebooks?.[index]?.notionPageId?.message}
          />
        </div>
      ))}
      <Button use="primary" isDisabled={isSaving} label="Save" />
    </form>
  );
}

export default EbooksSetupForm;
