import React, { ReactNode } from "react";
import classNames from "classnames";
import { FormFieldState } from "./form-field-state";
import styles from "./TextField.module.css";

export type TextFieldType =
  | "text"
  | "email"
  | "password"
  | "number"
  | "datetime-local"
  | "date"
  | "url"
  | "hidden";

interface Props {
  type?: TextFieldType;
  use?: FormFieldState;
  name: string;
  label: string;
  hint?: ReactNode;
  value?: string;
  onChange?: (value: string) => void;
  step?: number;
  labelIsHidden?: boolean;
  leftIcon?: React.ReactNode;
  isDisabled?: boolean;
}

function TextField(
  {
    type = "text",
    use,
    name,
    label,
    hint,
    value,
    onChange,
    step,
    labelIsHidden = false,
    leftIcon,
    isDisabled = false,
  }: Props,
  ref: React.Ref<HTMLInputElement>,
) {
  const onTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange != null) {
      onChange(event.target.value);
    }
  };

  return (
    <div className="field">
      <label
        className={classNames("label", { [styles.hidden]: labelIsHidden })}
      >
        {label}
      </label>
      <div
        className={classNames("control", {
          "has-icons-left": leftIcon != null,
        })}
      >
        <input
          ref={ref}
          className={classNames("input", {
            [`is-${use}`]: use != null,
          })}
          type={type}
          name={name}
          value={value}
          onChange={onTextFieldChange}
          step={step}
          disabled={isDisabled}
        />
        {leftIcon && <span className="icon is-small is-left">{leftIcon}</span>}
      </div>
      {hint != null && (
        <p
          className={classNames("help", {
            [`is-${use}`]: use != null,
          })}
        >
          {hint}
        </p>
      )}
    </div>
  );
}

export default React.forwardRef(TextField);
