import React from "react";
import { useForm } from "react-hook-form";
import TextField from "./TextField";
import Button from "./Button";

export interface ExpenseCategoryInput {
  title: string;
  icon: string;
}

interface Props {
  defaultValues?: ExpenseCategoryInput;
  onSubmit: (expenseCategory: ExpenseCategoryInput) => void;
  isSaving?: boolean;
}

function ExpenseCategoryForm({
  defaultValues,
  onSubmit,
  isSaving = false,
}: Props) {
  const { register, handleSubmit, errors } = useForm<ExpenseCategoryInput>({
    defaultValues,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        use={errors.title != null ? "danger" : undefined}
        name="title"
        label="Title"
        ref={register({
          required: "Please enter a title for this category.",
        })}
        hint={errors.title?.message}
      />
      <TextField
        use={errors.icon != null ? "danger" : undefined}
        name="icon"
        label="Icon"
        ref={register({
          required: "Please choose an icon for this category.",
        })}
        hint={errors.icon?.message}
      />

      <Button use="primary" isDisabled={isSaving} label="Save" />
    </form>
  );
}

export default ExpenseCategoryForm;
