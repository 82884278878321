import React from "react";
import gql from "graphql-tag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ExpensesListItem, { ExpensesListItemFragment } from "./ExpensesListItem";
import Button from "./Button";
import { ExpensesListFragment_ExpenseFragment } from "../generated/graphql";
import styles from "./ExpensesList.module.css";

interface Props {
  expenses: ExpensesListFragment_ExpenseFragment[];
  loadMore?: () => void;
  isLoadingMore?: boolean;
}

function ExpensesList({ expenses, loadMore, isLoadingMore = false }: Props) {
  return (
    <div className={styles.table}>
      <>
        <div className={styles.headerCell}>Timestamp</div>
        <div className={styles.headerCell}>Category</div>
        <div className={styles.headerCell}>Amount</div>
      </>

      {expenses.map(expense => (
        <ExpensesListItem key={expense.id} expense={expense} />
      ))}

      {isLoadingMore && (
        <div className={styles.fullRow}>
          <FontAwesomeIcon
            icon={["fal", "spinner-third"]}
            spin={true}
            size="lg"
          />
          <span className={styles.loadingMessage}>
            Loading more expenses...
          </span>
        </div>
      )}

      {loadMore != null && !isLoadingMore && (
        <div className={styles.fullRow}>
          <Button use="info" label="Load More" onClick={loadMore} />
        </div>
      )}
    </div>
  );
}

export const ExpensesListFragment = gql`
  fragment ExpensesListFragment_Expense on Expense {
    id
    ...ExpensesListItemFragment_Expense
  }
  ${ExpensesListItemFragment}
`;

export default ExpensesList;
