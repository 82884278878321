import ApolloClient from "apollo-client";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { ApolloLink, Observable, Operation } from "apollo-link";
import { loader } from "graphql.macro";
import { getSessionToken } from "./storage/session-token-storage";
import introspectionQueryResultData from "./generated/fragment-types.json";
import addNotification from "./resolvers/add-notification";
import removeNotification from "./resolvers/remove-notification";
import updateNotificationsFlashState from "./resolvers/update-notifications-flash-state";

interface Subscription {
  closed: boolean;
  unsubscribe(): void;
}

const uri = process.env.REACT_APP_GRAPHQL_QUERY_URL;

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const cache = new InMemoryCache({ fragmentMatcher });

const typeDefs = loader("./local-state-schema.graphql");

const injectSessionToken = async (operation: Operation) => {
  const token = getSessionToken();
  operation.setContext({
    headers: {
      authorization: token != null ? `Bearer ${token}` : undefined,
    },
  });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle: Subscription | undefined;

      Promise.resolve(operation)
        .then(oper => injectSessionToken(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) {
          handle.unsubscribe();
        }
      };
    }),
);

const link = ApolloLink.from([
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  }),
  requestLink,
  new HttpLink({
    uri,
    credentials: "same-origin",
  }),
]);

const client = new ApolloClient({
  link,
  cache,
  typeDefs,
  resolvers: {
    Mutation: {
      addNotification,
      removeNotification,
      updateNotificationsFlashState,
    },
  },
});

const initializeCache = () =>
  cache.writeData({
    data: {
      isSignedIn: getSessionToken() != null,
      notifications: [],
    },
  });

initializeCache();
client.onResetStore(async () => initializeCache());

export default client;
