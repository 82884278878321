import React from "react";
import gql from "graphql-tag";
import { BunnyCommandsListFragment_BunnyCommandFragment } from "../generated/graphql";
import styles from "./BunnyCommandsList.module.css";

interface Props {
  commands: BunnyCommandsListFragment_BunnyCommandFragment[];
}

function BunnyCommandsList({ commands }: Props) {
  return (
    <table className={`table ${styles.commands}`}>
      <thead>
        <tr>
          <th>Trigger</th>
          <th>URL</th>
          <th>URL Template</th>
        </tr>
      </thead>
      <tbody>
        {commands.map(command => (
          <tr key={command?.id}>
            <td>{command?.trigger}</td>
            <td>{command?.url}</td>
            <td>{command?.urlTemplate}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export const BunnyCommandsListFragment = gql`
  fragment BunnyCommandsListFragment_BunnyCommand on BunnyCommand {
    id
    trigger
    url
    urlTemplate
  }
`;

export default BunnyCommandsList;
