import React, { useState } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { produce } from "immer";
import { Link } from "react-router-dom";
import { sub, format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Page from "./Page";
import TextField from "../components/TextField";
import useEnsureSignedIn from "../hooks/use-ensure-signed-in";
import flattenArray from "../utils/flatten-array";
import InterestedBooksList, {
  InterestedBooksListFragment,
} from "../components/InterestedBooksList";
import {
  InterestedBooksListPageQuery,
  InterestedBooksListPageQueryVariables,
} from "../generated/graphql";

const INTERESTED_BOOKS_LIST_PAGE_QUERY = gql`
  query InterestedBooksListPage(
    $searchQuery: String
    $after: String
    $oneWeekAgo: String!
    $oneMonthAgo: String!
  ) {
    interestedBooks(searchQuery: $searchQuery, first: 30, after: $after) {
      nodes {
        ...InterestedBooksListFragment_InterestedBook
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${InterestedBooksListFragment}
`;

const NOW = new Date();
const ONE_WEEK_AGO = sub(NOW, { weeks: 1 });
const ONE_MONTH_AGO = sub(NOW, { months: 1 });

const DATE_FORMAT = "yyyy-MM-dd";
const ONE_WEEK_AGO_STR = format(ONE_WEEK_AGO, DATE_FORMAT);
const ONE_MONTH_AGO_STR = format(ONE_MONTH_AGO, DATE_FORMAT);

function InterestedBooksListPage() {
  useEnsureSignedIn();

  const [searchQuery, setSearchQuery] = useState("");

  const { data, loading, fetchMore } = useQuery<
    InterestedBooksListPageQuery,
    InterestedBooksListPageQueryVariables
  >(INTERESTED_BOOKS_LIST_PAGE_QUERY, {
    variables: {
      searchQuery,
      oneWeekAgo: ONE_WEEK_AGO_STR,
      oneMonthAgo: ONE_MONTH_AGO_STR,
    },
    fetchPolicy: "cache-and-network",
    partialRefetch: true,
  });

  if (data == null) {
    return <div>Loading...</div>;
  }

  const hasMore = data.interestedBooks.pageInfo.hasNextPage;

  const loadMore = () => {
    fetchMore({
      variables: {
        after: data.interestedBooks.pageInfo.endCursor,
      },
      updateQuery(previousResult, { fetchMoreResult }) {
        if (fetchMoreResult == null) {
          return previousResult;
        }

        return produce(previousResult, result => {
          result.interestedBooks.nodes = [
            ...(previousResult.interestedBooks.nodes || []),
            ...(fetchMoreResult.interestedBooks.nodes || []),
          ];
          result.interestedBooks.pageInfo =
            fetchMoreResult.interestedBooks.pageInfo;
        });
      },
    });
  };

  const interestedBooks = flattenArray(data.interestedBooks.nodes);

  return (
    <Page
      title="Interested Books"
      actionButton={<Link to="/interested_books/new">Add</Link>}
    >
      <TextField
        name="Search"
        label="Search"
        labelIsHidden={true}
        leftIcon={<FontAwesomeIcon icon={["fal", "search"]} />}
        value={searchQuery}
        onChange={setSearchQuery}
      />
      <InterestedBooksList
        books={interestedBooks}
        isLoadingMore={loading}
        loadMore={hasMore ? loadMore : undefined}
      />
    </Page>
  );
}

export default InterestedBooksListPage;
