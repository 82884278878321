import { useMemo } from "react";
import { IconPrefix, IconDefinition } from "@fortawesome/fontawesome-svg-core";
import findIcon from "../utils/find-icon";

function useMemoizeFindIcon(
  icon: string,
  prefix: IconPrefix = "fal",
): IconDefinition | null {
  return useMemo(() => findIcon(icon, prefix), [icon, prefix]);
}

export default useMemoizeFindIcon;
