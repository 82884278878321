import React from "react";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import useMemoizeFindIcon from "../hooks/use-memoize-find-icon";

interface Props extends Omit<FontAwesomeIconProps, "icon"> {
  icon: string;
  containerClassName?: string;
}

function DynamicIcon({ icon, containerClassName, ...props }: Props) {
  const iconDefinition = useMemoizeFindIcon(icon);

  if (iconDefinition == null) {
    return null;
  }

  return (
    <div className={containerClassName}>
      <FontAwesomeIcon icon={iconDefinition} {...props} />
    </div>
  );
}

export default DynamicIcon;
