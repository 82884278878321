import React from "react";
import Select, { Props as SelectProps } from "./Select";
import SELECTED_CURRENCIES from "../configs/supported-currencies";

type Props = Omit<SelectProps<string>, "options">;

const OPTIONS = SELECTED_CURRENCIES.map(currency => ({
  value: currency,
  label: currency,
}));

function CurrencySelector(props: Props, ref?: React.Ref<HTMLSelectElement>) {
  return <Select {...props} options={OPTIONS} ref={ref} />;
}

export default React.forwardRef(CurrencySelector);
