import React from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useSearchParams } from "react-router-dom";
import Page from "./Page";
import EbooksSetupForm, {
  ValidatedFormValues,
} from "../components/EbooksSetupForm";
import useNotifications from "../hooks/use-notifications";
import {
  EbooksSetupPageQuery,
  EbooksSetupPageQueryVariables,
  SetupEbooksMutation_UpdateEbooksNotionPageIdMutation,
  SetupEbooksMutation_UpdateEbooksNotionPageIdMutationVariables,
  NotificationFlashState,
  NotificationType,
} from "../generated/graphql";

const EBOOKS_SETUP_PAGE_QUERY = gql`
  query EbooksSetupPage($ids: [ID!]!) {
    ebooksByIds(ids: $ids) {
      id
      title
      notionPageId
    }
  }
`;

const SETUP_EBOOKS_MUTATION = gql`
  mutation SetupEbooksMutation_UpdateEbooksNotionPageId(
    $input: [UpdateEbookNotionPageIdInput!]!
  ) {
    bulkUpdateEbookNotionPageId(input: $input) {
      __typename
      id
      title
      notionPageId
    }
  }
`;

function EbooksSetupPage() {
  const [searchParams] = useSearchParams();
  const encodedIds = searchParams.get("ids") ?? "";
  const ids = encodedIds.split(",");

  const { addNotification } = useNotifications();

  const { data } = useQuery<
    EbooksSetupPageQuery,
    EbooksSetupPageQueryVariables
  >(EBOOKS_SETUP_PAGE_QUERY, { variables: { ids } });

  const [setupEbooks, { loading: isSaving }] = useMutation<
    SetupEbooksMutation_UpdateEbooksNotionPageIdMutation,
    SetupEbooksMutation_UpdateEbooksNotionPageIdMutationVariables
  >(SETUP_EBOOKS_MUTATION);

  if (data == null) {
    return <div>Loading...</div>;
  }

  const ebooks = data.ebooksByIds;
  const defaultValues = {
    ebooks: ebooks.map(({ id, title, notionPageId }) => ({
      id,
      title,
      notionPageId,
    })),
  };

  const onSubmit = async (formValues: ValidatedFormValues) => {
    const payload = formValues.ebooks.map(ebook => ({
      id: ebook.id,
      notionPageId: ebook.notionPageId,
    }));

    const { data, errors } = await setupEbooks({
      variables: { input: payload },
    });

    // TODO Handle this at link level
    if (errors != null && errors.length > 0) {
      const messages = errors.map(error => error.message);
      alert(`GraphQL errors:\n${messages.join("\n")}`);
      return;
    }

    if (data == null) {
      alert("Empty response from GraphQL mutation.");
      return;
    }

    await addNotification(
      "Setup complete. Please return to the terminal to complete the import.",
      NotificationFlashState.CurrentPage,
      NotificationType.Success,
    );
  };

  return (
    <Page title="Ebooks Setup">
      <EbooksSetupForm
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        isSaving={isSaving}
      />
    </Page>
  );
}

export default EbooksSetupPage;
