import React from "react";
import gql from "graphql-tag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "./Button";
import RecurringExpensesListItem, {
  RecurringExpensesListItemFragment,
} from "./RecurringExpensesListItem";
import { RecurringExpensesListFragment_RecurringExpenseFragment } from "../generated/graphql";
import styles from "./RecurringExpensesList.module.css";

interface Props {
  recurringExpenses: RecurringExpensesListFragment_RecurringExpenseFragment[];
  loadMore?: () => void;
  isLoadingMore?: boolean;
}

function RecurringExpensesList({
  recurringExpenses,
  loadMore,
  isLoadingMore,
}: Props) {
  return (
    <div className={styles.table}>
      <>
        <div className={styles.headerCell}>Title</div>
        <div className={styles.headerCell}>Validity</div>
        <div className={styles.headerCell}>Amount</div>
      </>

      {recurringExpenses.map(recurringExpense => (
        <RecurringExpensesListItem
          key={recurringExpense.id}
          recurringExpense={recurringExpense}
        />
      ))}

      {isLoadingMore && (
        <div className={styles.fullRow}>
          <FontAwesomeIcon
            icon={["fal", "spinner-third"]}
            spin={true}
            size="lg"
          />
          <span className={styles.loadingMessage}>
            Loading more recurring expenses...
          </span>
        </div>
      )}

      {loadMore != null && !isLoadingMore && (
        <div className={styles.fullRow}>
          <Button use="info" label="Load More" onClick={loadMore} />
        </div>
      )}
    </div>
  );
}

export const RecurringExpensesListFragment = gql`
  fragment RecurringExpensesListFragment_RecurringExpense on RecurringExpense {
    id
    ...RecurringExpensesListItemFragment_RecurringExpense
  }
  ${RecurringExpensesListItemFragment}
`;

export default RecurringExpensesList;
