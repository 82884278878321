import React from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import Page from "./Page";
import Button from "../components/Button";
import useEnsureSignedIn from "../hooks/use-ensure-signed-in";
import {
  PocketAuthorizationPage_CreatePocketAuthorizationRequestMutation,
  PocketAuthorizationPage_CreatePocketAuthorizationRequestMutationVariables,
} from "../generated/graphql";

const POCKET_AUTHORIZATION_PAGE_CREATE_POCKET_AUTHORIZATION_REQUEST_MUTATION = gql`
  mutation PocketAuthorizationPage_CreatePocketAuthorizationRequest {
    createPocketAuthorizationRequest
  }
`;

function PocketAuthorizationPage() {
  useEnsureSignedIn();

  const [request, { loading }] = useMutation<
    PocketAuthorizationPage_CreatePocketAuthorizationRequestMutation,
    PocketAuthorizationPage_CreatePocketAuthorizationRequestMutationVariables
  >(POCKET_AUTHORIZATION_PAGE_CREATE_POCKET_AUTHORIZATION_REQUEST_MUTATION);

  const onClick = async () => {
    const { data, errors } = await request();

    if (errors != null && errors.length > 0) {
      const messages = errors.map(error => error.message);
      alert(`GraphQL errors:\n${messages.join("\n")}`);
      return;
    }

    if (data == null) {
      alert("Empty response from GraphQL mutation.");
      return;
    }

    const code = data.createPocketAuthorizationRequest;
    if (code == null) {
      alert("Request failed.");
      return;
    }

    const baseUrl = window.location.origin;
    const callbackUrl = `${baseUrl}/services/pocket/callback`;

    const url = new URL("https://getpocket.com/auth/authorize");
    url.searchParams.append("request_token", code);
    url.searchParams.append("redirect_uri", callbackUrl);
    window.location.href = url.href;
  };

  return (
    <Page title="Pocket Authorization">
      <Button onClick={onClick} isDisabled={loading} label="Authorize" />
    </Page>
  );
}

export default PocketAuthorizationPage;
