export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type BookPrice = {
  __typename?: 'BookPrice';
  date: Scalars['String'];
  price: Scalars['Float'];
};

export type BunnyCommand = {
  __typename?: 'BunnyCommand';
  id: Scalars['ID'];
  trigger: Scalars['String'];
  url: Scalars['String'];
  urlTemplate?: Maybe<Scalars['String']>;
};

/** The connection type for BunnyCommand. */
export type BunnyCommandConnection = {
  __typename?: 'BunnyCommandConnection';
  count: Scalars['Int'];
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BunnyCommandEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BunnyCommand>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type BunnyCommandEdge = {
  __typename?: 'BunnyCommandEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BunnyCommand>;
};

export type BunnyCommandOrError = BunnyCommand | ValidationErrors;

export type BunnyProfile = {
  __typename?: 'BunnyProfile';
  commands: BunnyCommandConnection;
  id: Scalars['ID'];
  name: Scalars['String'];
  string: Scalars['String'];
  token: Scalars['String'];
};


export type BunnyProfileCommandsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for BunnyProfile. */
export type BunnyProfileConnection = {
  __typename?: 'BunnyProfileConnection';
  count: Scalars['Int'];
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BunnyProfileEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BunnyProfile>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type BunnyProfileEdge = {
  __typename?: 'BunnyProfileEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BunnyProfile>;
};

export type BunnyProfileOrError = BunnyProfile | ValidationErrors;

export type CategoricalExpenseReport = {
  __typename?: 'CategoricalExpenseReport';
  category: ExpenseCategory;
  total: Scalars['Float'];
};

export type CreateEbookInput = {
  title: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  sourceId: Scalars['String'];
  vendor: Scalars['String'];
  metadataJson?: Maybe<Scalars['String']>;
};

export type CreateTextHighlightInput = {
  highlightableId: Scalars['ID'];
  highlightableType: Scalars['String'];
  text: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  sourceId: Scalars['String'];
  metadataJson?: Maybe<Scalars['String']>;
};

export type Ebook = {
  __typename?: 'Ebook';
  author?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  notionPageId?: Maybe<Scalars['String']>;
  sourceId: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  vendor: Scalars['String'];
};

export type Expense = {
  __typename?: 'Expense';
  amount: Scalars['Float'];
  category: ExpenseCategory;
  currency: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  timestamp: Scalars['Int'];
};

export type ExpenseCategory = {
  __typename?: 'ExpenseCategory';
  icon: Scalars['String'];
  id: Scalars['ID'];
  simpleBudget?: Maybe<SimpleBudget>;
  title: Scalars['String'];
};

export type ExpenseCategoryOrError = ExpenseCategory | ValidationErrors;

/** The connection type for Expense. */
export type ExpenseConnection = {
  __typename?: 'ExpenseConnection';
  count: Scalars['Int'];
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ExpenseEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Expense>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ExpenseEdge = {
  __typename?: 'ExpenseEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Expense>;
};

export type ExpenseOrError = Expense | ValidationErrors;

export type ExpenseReport = {
  __typename?: 'ExpenseReport';
  byCategory: Array<CategoricalExpenseReport>;
  currency: Scalars['String'];
  expenses: ExpenseConnection;
  from: Scalars['String'];
  to: Scalars['String'];
  total: Scalars['Float'];
};


export type ExpenseReportExpensesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type InterestedBook = {
  __typename?: 'InterestedBook';
  id: Scalars['ID'];
  price?: Maybe<BookPrice>;
  prices: Array<BookPrice>;
  title: Scalars['String'];
  url: Scalars['String'];
};


export type InterestedBookPriceArgs = {
  date?: Maybe<Scalars['String']>;
};

/** The connection type for InterestedBook. */
export type InterestedBookConnection = {
  __typename?: 'InterestedBookConnection';
  count: Scalars['Int'];
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InterestedBookEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<InterestedBook>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type InterestedBookEdge = {
  __typename?: 'InterestedBookEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<InterestedBook>;
};

export type InterestedBookOrError = InterestedBook | ValidationErrors;

export type Mutation = {
  __typename?: 'Mutation';
  addNotification: Notification;
  bulkCreateEbooks: Array<Ebook>;
  bulkCreateTextHighlights: Array<TextHighlight>;
  bulkUpdateEbookNotionPageId: Array<Ebook>;
  createBunnyCommand?: Maybe<BunnyCommandOrError>;
  createBunnyProfile?: Maybe<BunnyProfileOrError>;
  createExpense?: Maybe<ExpenseOrError>;
  createExpenseCategory?: Maybe<ExpenseCategoryOrError>;
  createInterestedBook?: Maybe<InterestedBookOrError>;
  createOrUpdateSimpleBudget?: Maybe<SimpleBudgetOrError>;
  createPocketAuthorizationRequest?: Maybe<Scalars['String']>;
  createRecurringExpense?: Maybe<RecurringExpenseOrError>;
  deleteBunnyCommand?: Maybe<BunnyCommand>;
  instapaperAuthorization: Scalars['String'];
  pocketAuthorization: Scalars['String'];
  removeNotification?: Maybe<Notification>;
  setTimestamp: Scalars['Int'];
  signIn?: Maybe<Scalars['String']>;
  signUp: UserOrError;
  updateBookPrices?: Maybe<Array<InterestedBook>>;
  updateBunnyCommand?: Maybe<BunnyCommandOrError>;
  updateNotificationsFlashState: Array<Notification>;
};


export type MutationAddNotificationArgs = {
  message: Scalars['String'];
  flashState: NotificationFlashState;
  type?: Maybe<NotificationType>;
};


export type MutationBulkCreateEbooksArgs = {
  input: Array<CreateEbookInput>;
};


export type MutationBulkCreateTextHighlightsArgs = {
  input: Array<CreateTextHighlightInput>;
};


export type MutationBulkUpdateEbookNotionPageIdArgs = {
  input: Array<UpdateEbookNotionPageIdInput>;
};


export type MutationCreateBunnyCommandArgs = {
  profileId: Scalars['ID'];
  trigger: Scalars['String'];
  url: Scalars['String'];
  urlTemplate?: Maybe<Scalars['String']>;
};


export type MutationCreateBunnyProfileArgs = {
  name: Scalars['String'];
};


export type MutationCreateExpenseArgs = {
  currency: Scalars['String'];
  amount: Scalars['Float'];
  categoryId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  timestamp: Scalars['Int'];
};


export type MutationCreateExpenseCategoryArgs = {
  title: Scalars['String'];
  icon: Scalars['String'];
};


export type MutationCreateInterestedBookArgs = {
  title: Scalars['String'];
  url: Scalars['String'];
};


export type MutationCreateOrUpdateSimpleBudgetArgs = {
  currency: Scalars['String'];
  amount: Scalars['Float'];
  period: SimpleBudgetPeriod;
  categoryId: Scalars['ID'];
};


export type MutationCreateRecurringExpenseArgs = {
  title: Scalars['String'];
  currency: Scalars['String'];
  amount: Scalars['Float'];
  frequency: RecurringExpenseFrequency;
  validFrom: Scalars['String'];
  validUntil?: Maybe<Scalars['String']>;
  categoryId: Scalars['ID'];
};


export type MutationDeleteBunnyCommandArgs = {
  commandId: Scalars['ID'];
};


export type MutationInstapaperAuthorizationArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationRemoveNotificationArgs = {
  id: Scalars['ID'];
};


export type MutationSetTimestampArgs = {
  key: Scalars['String'];
  timestamp: Scalars['Int'];
};


export type MutationSignInArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationSignUpArgs = {
  name: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationUpdateBookPricesArgs = {
  updatedBookPrices: Array<UpdatedBookPrice>;
};


export type MutationUpdateBunnyCommandArgs = {
  commandId: Scalars['ID'];
  trigger: Scalars['String'];
  url: Scalars['String'];
  urlTemplate?: Maybe<Scalars['String']>;
};

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['ID'];
  flashState: NotificationFlashState;
  type?: Maybe<NotificationType>;
  message: Scalars['String'];
};

export enum NotificationFlashState {
  CurrentPage = 'CURRENT_PAGE',
  NextPage = 'NEXT_PAGE',
  Persistent = 'PERSISTENT'
}

export enum NotificationType {
  Primary = 'PRIMARY',
  Success = 'SUCCESS',
  Warning = 'WARNING',
  Danger = 'DANGER'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  bunnyProfile?: Maybe<BunnyProfile>;
  bunnyProfiles: BunnyProfileConnection;
  currentUser: User;
  ebooksByIds: Array<Ebook>;
  expenseCategories: Array<ExpenseCategory>;
  expenseCategory?: Maybe<ExpenseCategory>;
  expenses: ExpenseConnection;
  interestedBook?: Maybe<InterestedBook>;
  interestedBooks: InterestedBookConnection;
  isSignedIn: Scalars['Boolean'];
  monthlyExpenseReport: ExpenseReport;
  notifications: Array<Notification>;
  ping: Scalars['String'];
  recurringExpenses: RecurringExpenseConnection;
  timestamp?: Maybe<Scalars['Int']>;
};


export type QueryBunnyProfileArgs = {
  id: Scalars['ID'];
};


export type QueryBunnyProfilesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryEbooksByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryExpenseCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryExpensesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryInterestedBookArgs = {
  id: Scalars['ID'];
};


export type QueryInterestedBooksArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  searchQuery?: Maybe<Scalars['String']>;
};


export type QueryMonthlyExpenseReportArgs = {
  year: Scalars['Int'];
  month: Scalars['Int'];
  currency?: Maybe<Scalars['String']>;
};


export type QueryRecurringExpensesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryTimestampArgs = {
  key: Scalars['String'];
};

export type RecurringExpense = {
  __typename?: 'RecurringExpense';
  amount: Scalars['Float'];
  category: ExpenseCategory;
  currency: Scalars['String'];
  frequency: RecurringExpenseFrequency;
  id: Scalars['ID'];
  title: Scalars['String'];
  validFrom: Scalars['String'];
  validUntil?: Maybe<Scalars['String']>;
};

/** The connection type for RecurringExpense. */
export type RecurringExpenseConnection = {
  __typename?: 'RecurringExpenseConnection';
  count: Scalars['Int'];
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RecurringExpenseEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<RecurringExpense>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type RecurringExpenseEdge = {
  __typename?: 'RecurringExpenseEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<RecurringExpense>;
};

export enum RecurringExpenseFrequency {
  /** Mothly recurring expense */
  Monthly = 'MONTHLY'
}

export type RecurringExpenseOrError = RecurringExpense | ValidationErrors;

export type SimpleBudget = {
  __typename?: 'SimpleBudget';
  amount: Scalars['Float'];
  category: ExpenseCategory;
  convertedAmount?: Maybe<Scalars['Float']>;
  currency: Scalars['String'];
  period: SimpleBudgetPeriod;
};


export type SimpleBudgetConvertedAmountArgs = {
  currency: Scalars['String'];
};

export type SimpleBudgetOrError = SimpleBudget | ValidationErrors;

export enum SimpleBudgetPeriod {
  /** Budget renews daily */
  Daily = 'DAILY',
  /** Budget renews monthly */
  Monthly = 'MONTHLY'
}

export type TextHighlight = {
  __typename?: 'TextHighlight';
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  sourceId: Scalars['String'];
  text: Scalars['String'];
};

export type UpdateEbookNotionPageIdInput = {
  id: Scalars['ID'];
  notionPageId: Scalars['ID'];
};

export type UpdatedBookPrice = {
  bookId: Scalars['ID'];
  price: Scalars['Float'];
  date: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  connectedToInstapaper: Scalars['Boolean'];
  connectedToPocket: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type UserOrError = User | ValidationErrors;

export type ValidationError = {
  __typename?: 'ValidationError';
  field: Scalars['String'];
  messages: Array<Scalars['String']>;
};

export type ValidationErrors = {
  __typename?: 'ValidationErrors';
  errors: Array<ValidationError>;
};

export type BunnyCommandsListFragment_BunnyCommandFragment = (
  { __typename?: 'BunnyCommand' }
  & Pick<BunnyCommand, 'id' | 'trigger' | 'url' | 'urlTemplate'>
);

export type ExpenseBreakdownPieChart_ExpenseReportFragment = (
  { __typename?: 'ExpenseReport' }
  & Pick<ExpenseReport, 'currency'>
  & { byCategory: Array<(
    { __typename?: 'CategoricalExpenseReport' }
    & Pick<CategoricalExpenseReport, 'total'>
    & { category: (
      { __typename?: 'ExpenseCategory' }
      & Pick<ExpenseCategory, 'title'>
    ) }
  )> }
);

export type ExpenseCategorySelector_ExpenseCategoriesFragment = (
  { __typename?: 'Query' }
  & { expenseCategories: Array<(
    { __typename?: 'ExpenseCategory' }
    & Pick<ExpenseCategory, 'id' | 'title'>
  )> }
);

export type ExpenseForm_ExpenseCategoriesFragment = (
  { __typename?: 'Query' }
  & ExpenseCategorySelector_ExpenseCategoriesFragment
);

export type ExpenseReport_ExpenseReportFragment = (
  { __typename?: 'ExpenseReport' }
  & Pick<ExpenseReport, 'from' | 'to' | 'currency' | 'total'>
  & { expenses: (
    { __typename?: 'ExpenseConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Expense' }
      & ExpensesListFragment_ExpenseFragment
    )>>> }
  ) }
  & ExpenseBreakdownPieChart_ExpenseReportFragment
  & ExpenseReportBudgetSection_ExpenseReportFragment
);

export type ExpenseReportBudgetItem_CategoricalExpenseReportFragment = (
  { __typename?: 'CategoricalExpenseReport' }
  & Pick<CategoricalExpenseReport, 'total'>
  & { category: (
    { __typename?: 'ExpenseCategory' }
    & Pick<ExpenseCategory, 'title'>
    & { simpleBudget?: Maybe<(
      { __typename?: 'SimpleBudget' }
      & Pick<SimpleBudget, 'currency' | 'amount' | 'convertedAmount' | 'period'>
    )> }
  ) }
);

export type ExpenseReportBudgetSection_ExpenseReportFragment = (
  { __typename?: 'ExpenseReport' }
  & { byCategory: Array<(
    { __typename?: 'CategoricalExpenseReport' }
    & { category: (
      { __typename?: 'ExpenseCategory' }
      & Pick<ExpenseCategory, 'id'>
    ) }
    & ExpenseReportBudgetItem_CategoricalExpenseReportFragment
  )> }
);

export type ExpensesListFragment_ExpenseFragment = (
  { __typename?: 'Expense' }
  & Pick<Expense, 'id'>
  & ExpensesListItemFragment_ExpenseFragment
);

export type ExpensesListItemFragment_ExpenseFragment = (
  { __typename?: 'Expense' }
  & Pick<Expense, 'currency' | 'amount' | 'description' | 'timestamp'>
  & { category: (
    { __typename?: 'ExpenseCategory' }
    & Pick<ExpenseCategory, 'icon' | 'title'>
  ) }
);

export type GlobalNotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GlobalNotificationsQuery = (
  { __typename?: 'Query' }
  & { notifications: Array<(
    { __typename?: 'Notification' }
    & Pick<Notification, 'id' | 'message' | 'type'>
  )> }
);

export type GlobalNotifications_UpdateFlashStateMutationVariables = Exact<{ [key: string]: never; }>;


export type GlobalNotifications_UpdateFlashStateMutation = (
  { __typename?: 'Mutation' }
  & { updateNotificationsFlashState: Array<{ __typename: 'Notification' }> }
);

export type InterestedBooksListFragment_InterestedBookFragment = (
  { __typename?: 'InterestedBook' }
  & Pick<InterestedBook, 'id'>
  & InterestedBooksListItemFragment_InterestedBookFragment
);

export type InterestedBooksListItemFragment_InterestedBookFragment = (
  { __typename?: 'InterestedBook' }
  & InterestedBooksListItemTitleCellFragment_InterestedBookFragment
  & InterestedBooksListItemPriceCellFragment_InterestedBookFragment
  & InterestedBooksListItemOneWeekPriceComparisonCellFragment_InterestedBookFragment
  & InterestedBooksListItemOneMonthPriceComparisonCellFragment_InterestedBookFragment
);

export type InterestedBooksListItemOneMonthPriceComparisonCellFragment_InterestedBookFragment = (
  { __typename?: 'InterestedBook' }
  & { latestPrice?: Maybe<(
    { __typename?: 'BookPrice' }
    & InterestedBooksListItemPriceComparisonCellFragment_LatestPriceFragment
  )>, priceOneMonthAgo?: Maybe<(
    { __typename?: 'BookPrice' }
    & InterestedBooksListItemPriceComparisonCellFragment_ReferencePriceFragment
  )> }
);

export type InterestedBooksListItemOneWeekPriceComparisonCellFragment_InterestedBookFragment = (
  { __typename?: 'InterestedBook' }
  & { latestPrice?: Maybe<(
    { __typename?: 'BookPrice' }
    & InterestedBooksListItemPriceComparisonCellFragment_LatestPriceFragment
  )>, priceOneWeekAgo?: Maybe<(
    { __typename?: 'BookPrice' }
    & InterestedBooksListItemPriceComparisonCellFragment_ReferencePriceFragment
  )> }
);

export type InterestedBooksListItemPriceCellFragment_InterestedBookFragment = (
  { __typename?: 'InterestedBook' }
  & { latestPrice?: Maybe<(
    { __typename?: 'BookPrice' }
    & Pick<BookPrice, 'date' | 'price'>
  )> }
);

export type InterestedBooksListItemPriceComparisonCellFragment_LatestPriceFragment = (
  { __typename?: 'BookPrice' }
  & Pick<BookPrice, 'price'>
);

export type InterestedBooksListItemPriceComparisonCellFragment_ReferencePriceFragment = (
  { __typename?: 'BookPrice' }
  & Pick<BookPrice, 'date' | 'price'>
);

export type InterestedBooksListItemTitleCellFragment_InterestedBookFragment = (
  { __typename?: 'InterestedBook' }
  & Pick<InterestedBook, 'id' | 'title'>
);

export type RecurringExpenseForm_ExpenseCategoriesFragment = (
  { __typename?: 'Query' }
  & ExpenseCategorySelector_ExpenseCategoriesFragment
);

export type RecurringExpensesListFragment_RecurringExpenseFragment = (
  { __typename?: 'RecurringExpense' }
  & Pick<RecurringExpense, 'id'>
  & RecurringExpensesListItemFragment_RecurringExpenseFragment
);

export type RecurringExpensesListItemFragment_RecurringExpenseFragment = (
  { __typename?: 'RecurringExpense' }
  & Pick<RecurringExpense, 'title' | 'currency' | 'amount' | 'frequency' | 'validFrom' | 'validUntil'>
  & { category: (
    { __typename?: 'ExpenseCategory' }
    & Pick<ExpenseCategory, 'icon'>
  ) }
);

export type UseEnsureSignedInQueryVariables = Exact<{ [key: string]: never; }>;


export type UseEnsureSignedInQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'isSignedIn'>
);

export type UseNotifications_AddNotificationMutationVariables = Exact<{
  message: Scalars['String'];
  flashState: NotificationFlashState;
  type?: Maybe<NotificationType>;
}>;


export type UseNotifications_AddNotificationMutation = (
  { __typename?: 'Mutation' }
  & { addNotification: (
    { __typename?: 'Notification' }
    & Pick<Notification, 'id'>
  ) }
);

export type UseNotifications_RemoveNotificationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UseNotifications_RemoveNotificationMutation = (
  { __typename?: 'Mutation' }
  & { removeNotification?: Maybe<{ __typename: 'Notification' }> }
);

export type BunnyProfileDetailsPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BunnyProfileDetailsPageQuery = (
  { __typename?: 'Query' }
  & { bunnyProfile?: Maybe<(
    { __typename?: 'BunnyProfile' }
    & Pick<BunnyProfile, 'name' | 'token'>
    & { commands: (
      { __typename?: 'BunnyCommandConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'BunnyCommand' }
        & BunnyCommandsListFragment_BunnyCommandFragment
      )>>> }
    ) }
  )> }
);

export type CreateBunnyCommandForm_CreateCommandMutationVariables = Exact<{
  profileId: Scalars['ID'];
  trigger: Scalars['String'];
  url: Scalars['String'];
  urlTemplate?: Maybe<Scalars['String']>;
}>;


export type CreateBunnyCommandForm_CreateCommandMutation = (
  { __typename?: 'Mutation' }
  & { createBunnyCommand?: Maybe<{ __typename?: 'BunnyCommand' } | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'field' | 'messages'>
    )> }
  )> }
);

export type BunnyProfilesListPageQueryVariables = Exact<{ [key: string]: never; }>;


export type BunnyProfilesListPageQuery = (
  { __typename?: 'Query' }
  & { bunnyProfiles: (
    { __typename?: 'BunnyProfileConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'BunnyProfile' }
      & Pick<BunnyProfile, 'id' | 'name'>
    )>>> }
  ) }
);

export type CreateBunnyProfileMutation_CreateProfileMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateBunnyProfileMutation_CreateProfileMutation = (
  { __typename?: 'Mutation' }
  & { createBunnyProfile?: Maybe<{ __typename?: 'BunnyProfile' } | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'field' | 'messages'>
    )> }
  )> }
);

export type EbooksSetupPageQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type EbooksSetupPageQuery = (
  { __typename?: 'Query' }
  & { ebooksByIds: Array<(
    { __typename?: 'Ebook' }
    & Pick<Ebook, 'id' | 'title' | 'notionPageId'>
  )> }
);

export type SetupEbooksMutation_UpdateEbooksNotionPageIdMutationVariables = Exact<{
  input: Array<UpdateEbookNotionPageIdInput> | UpdateEbookNotionPageIdInput;
}>;


export type SetupEbooksMutation_UpdateEbooksNotionPageIdMutation = (
  { __typename?: 'Mutation' }
  & { bulkUpdateEbookNotionPageId: Array<(
    { __typename: 'Ebook' }
    & Pick<Ebook, 'id' | 'title' | 'notionPageId'>
  )> }
);

export type EditSimpleBudgetPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type EditSimpleBudgetPageQuery = (
  { __typename?: 'Query' }
  & { expenseCategory?: Maybe<(
    { __typename?: 'ExpenseCategory' }
    & { simpleBudget?: Maybe<(
      { __typename?: 'SimpleBudget' }
      & Pick<SimpleBudget, 'currency' | 'amount' | 'period'>
    )> }
  )> }
);

export type EditSimpleBudgetPage_UpdateBudgetMutationVariables = Exact<{
  currency: Scalars['String'];
  amount: Scalars['Float'];
  period: SimpleBudgetPeriod;
  categoryId: Scalars['ID'];
}>;


export type EditSimpleBudgetPage_UpdateBudgetMutation = (
  { __typename?: 'Mutation' }
  & { createOrUpdateSimpleBudget?: Maybe<{ __typename?: 'SimpleBudget' } | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'field' | 'messages'>
    )> }
  )> }
);

export type ExpenseCategoriesListPageQueryVariables = Exact<{ [key: string]: never; }>;


export type ExpenseCategoriesListPageQuery = (
  { __typename?: 'Query' }
  & { expenseCategories: Array<(
    { __typename?: 'ExpenseCategory' }
    & Pick<ExpenseCategory, 'id' | 'title' | 'icon'>
  )> }
);

export type ExpensesListPageQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
}>;


export type ExpensesListPageQuery = (
  { __typename?: 'Query' }
  & { expenses: (
    { __typename?: 'ExpenseConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Expense' }
      & ExpensesListFragment_ExpenseFragment
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
    ) }
  ) }
);

export type InstapaperAuthorizationPage_InstapaperAuthorizationMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type InstapaperAuthorizationPage_InstapaperAuthorizationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'instapaperAuthorization'>
);

export type InterestedBookDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type InterestedBookDetailsQuery = (
  { __typename?: 'Query' }
  & { interestedBook?: Maybe<(
    { __typename?: 'InterestedBook' }
    & Pick<InterestedBook, 'title' | 'url'>
    & { prices: Array<(
      { __typename?: 'BookPrice' }
      & Pick<BookPrice, 'date' | 'price'>
    )> }
  )> }
);

export type InterestedBooksListPageQueryVariables = Exact<{
  searchQuery?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  oneWeekAgo: Scalars['String'];
  oneMonthAgo: Scalars['String'];
}>;


export type InterestedBooksListPageQuery = (
  { __typename?: 'Query' }
  & { interestedBooks: (
    { __typename?: 'InterestedBookConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'InterestedBook' }
      & InterestedBooksListFragment_InterestedBookFragment
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
    ) }
  ) }
);

export type MonthlyExpenseReportPageQueryVariables = Exact<{
  year: Scalars['Int'];
  month: Scalars['Int'];
  currency: Scalars['String'];
  after?: Maybe<Scalars['String']>;
}>;


export type MonthlyExpenseReportPageQuery = (
  { __typename?: 'Query' }
  & { monthlyExpenseReport: (
    { __typename?: 'ExpenseReport' }
    & { expenses: (
      { __typename?: 'ExpenseConnection' }
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
      ) }
    ) }
    & ExpenseReport_ExpenseReportFragment
  ) }
);

export type NewExpenseCategoryPage_CreateExpenseCategoryMutationVariables = Exact<{
  title: Scalars['String'];
  icon: Scalars['String'];
}>;


export type NewExpenseCategoryPage_CreateExpenseCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createExpenseCategory?: Maybe<{ __typename?: 'ExpenseCategory' } | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'field' | 'messages'>
    )> }
  )> }
);

export type NewExpensePageQueryVariables = Exact<{ [key: string]: never; }>;


export type NewExpensePageQuery = (
  { __typename?: 'Query' }
  & ExpenseForm_ExpenseCategoriesFragment
);

export type NewExpensePage_CreateExpenseMutationVariables = Exact<{
  currency: Scalars['String'];
  amount: Scalars['Float'];
  categoryId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  timestamp: Scalars['Int'];
}>;


export type NewExpensePage_CreateExpenseMutation = (
  { __typename?: 'Mutation' }
  & { createExpense?: Maybe<{ __typename?: 'Expense' } | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'field' | 'messages'>
    )> }
  )> }
);

export type NewInterestedBookPage_CreateInterestedBookMutationVariables = Exact<{
  title: Scalars['String'];
  url: Scalars['String'];
}>;


export type NewInterestedBookPage_CreateInterestedBookMutation = (
  { __typename?: 'Mutation' }
  & { createInterestedBook?: Maybe<{ __typename?: 'InterestedBook' } | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'field' | 'messages'>
    )> }
  )> }
);

export type NewRecurringExpensePageQueryVariables = Exact<{ [key: string]: never; }>;


export type NewRecurringExpensePageQuery = (
  { __typename?: 'Query' }
  & RecurringExpenseForm_ExpenseCategoriesFragment
);

export type NewRecurringExpensePage_CreateRecurringExpenseMutationVariables = Exact<{
  title: Scalars['String'];
  currency: Scalars['String'];
  amount: Scalars['Float'];
  frequency: RecurringExpenseFrequency;
  validFrom: Scalars['String'];
  validUntil?: Maybe<Scalars['String']>;
  categoryId: Scalars['ID'];
}>;


export type NewRecurringExpensePage_CreateRecurringExpenseMutation = (
  { __typename?: 'Mutation' }
  & { createRecurringExpense?: Maybe<{ __typename?: 'RecurringExpense' } | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'field' | 'messages'>
    )> }
  )> }
);

export type PocketAuthorizationCallbackPage_PocketAuthorizationMutationVariables = Exact<{ [key: string]: never; }>;


export type PocketAuthorizationCallbackPage_PocketAuthorizationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'pocketAuthorization'>
);

export type PocketAuthorizationPage_CreatePocketAuthorizationRequestMutationVariables = Exact<{ [key: string]: never; }>;


export type PocketAuthorizationPage_CreatePocketAuthorizationRequestMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createPocketAuthorizationRequest'>
);

export type RecurringExpensesListQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
}>;


export type RecurringExpensesListQuery = (
  { __typename?: 'Query' }
  & { recurringExpenses: (
    { __typename?: 'RecurringExpenseConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'RecurringExpense' }
      & RecurringExpensesListFragment_RecurringExpenseFragment
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
    ) }
  ) }
);

export type ServicesListPageQueryVariables = Exact<{ [key: string]: never; }>;


export type ServicesListPageQuery = (
  { __typename?: 'Query' }
  & { currentUser: (
    { __typename?: 'User' }
    & Pick<User, 'connectedToInstapaper' | 'connectedToPocket'>
  ) }
);

export type SignInPage_SignInMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type SignInPage_SignInMutation = (
  { __typename?: 'Mutation' }
  & { sessionToken: Mutation['signIn'] }
);

export type AddNotificationResolverQueryVariables = Exact<{ [key: string]: never; }>;


export type AddNotificationResolverQuery = (
  { __typename?: 'Query' }
  & { notifications: Array<(
    { __typename?: 'Notification' }
    & Pick<Notification, 'id' | 'message' | 'flashState' | 'type'>
  )> }
);

export type RemoveNotificationResolverQueryVariables = Exact<{ [key: string]: never; }>;


export type RemoveNotificationResolverQuery = (
  { __typename?: 'Query' }
  & { notifications: Array<(
    { __typename?: 'Notification' }
    & Pick<Notification, 'id' | 'message' | 'flashState' | 'type'>
  )> }
);

export type UpdateNotificationsFlashStateResolverQueryVariables = Exact<{ [key: string]: never; }>;


export type UpdateNotificationsFlashStateResolverQuery = (
  { __typename?: 'Query' }
  & { notifications: Array<(
    { __typename?: 'Notification' }
    & Pick<Notification, 'id' | 'message' | 'flashState' | 'type'>
  )> }
);
