import React from "react";
import gql from "graphql-tag";
import { useForm } from "react-hook-form";
import formatDate from "date-fns/format";
import parseDate from "date-fns/parse";
import TextField from "./TextField";
import Button from "./Button";
import LoadingField from "./LoadingField";
import ExpenseCategorySelector, {
  ExpenseCategoriesFragment,
} from "./ExpenseCategorySelector";
import CurrencySelector from "./CurrencySelector";
import { ExpenseForm_ExpenseCategoriesFragment } from "../generated/graphql";
import format from "date-fns/format";

// We needed two interfaces as the react-hook-form outputs
// input.target.value, which is always string. (No type error
// even if we requested a number.) Not sure what's a good way
// around this. For now, let's just convert between these two.

export interface ExpenseInput {
  currency: string;
  amount: number;
  categoryId: string;
  description: string | null | undefined;
  timestamp: number;
}

interface ExpenseFieldValues {
  currency: string;
  amount: string;
  categoryId: string;
  description: string | null | undefined;
  timestamp: string;
}

interface Props {
  query: ExpenseForm_ExpenseCategoriesFragment;
  isQueryLoading: boolean;
  defaultValues?: ExpenseInput;
  onSubmit: (expense: ExpenseInput) => void;
  isSaving?: boolean;
}

const TIMESTAMP_FORMAT = "yyyy-MM-dd'T'HH:mm";

const toFieldValues = (input: ExpenseInput): ExpenseFieldValues => ({
  currency: input.currency,
  amount: input.amount.toString(),
  categoryId: input.categoryId,
  description: input.description,
  timestamp: format(input.timestamp, TIMESTAMP_FORMAT),
});

const fromValidatedFormValues = (input: ExpenseFieldValues): ExpenseInput => ({
  currency: input.currency,
  amount: parseFloat(input.amount),
  categoryId: input.categoryId,
  description: input.description,
  timestamp: parseDate(input.timestamp, TIMESTAMP_FORMAT, new Date()).getTime(),
});

function ExpenseForm({
  isSaving = false,
  query,
  isQueryLoading,
  defaultValues,
  onSubmit,
}: Props) {
  const { register, handleSubmit, errors } = useForm<ExpenseFieldValues>({
    defaultValues:
      defaultValues != null
        ? toFieldValues(defaultValues)
        : {
            currency: "SGD",
            categoryId: "",
            timestamp: formatDate(Date.now(), TIMESTAMP_FORMAT),
          },
  });

  const onFormSubmit = (input: ExpenseFieldValues) => {
    onSubmit(fromValidatedFormValues(input));
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <CurrencySelector
        use={errors.currency != null ? "danger" : undefined}
        name="currency"
        label="Currency"
        ref={register({
          required: "Please enter the currency for this expense.",
        })}
        hint={errors.currency?.message}
      />

      <TextField
        use={errors.amount != null ? "danger" : undefined}
        type="number"
        step={0.01}
        name="amount"
        label="Amount"
        ref={register({
          required: "Please enter the amount for this expense.",
          min: {
            value: 0.01,
            message:
              "Please enter a positive value as the amount for this expense.",
          },
        })}
        hint={errors.amount?.message}
      />

      {isQueryLoading ? (
        <LoadingField label="Category" hint="Loading available categories" />
      ) : (
        <ExpenseCategorySelector
          use={errors.categoryId != null ? "danger" : undefined}
          query={query}
          name="categoryId"
          label="Category"
          ref={register({
            required: "Please select a category for this expense.",
          })}
          hint={errors.categoryId?.message}
        />
      )}

      <TextField name="description" label="Description" ref={register} />

      <TextField
        use={errors.timestamp != null ? "danger" : undefined}
        type="datetime-local"
        name="timestamp"
        label="Timestamp"
        ref={register({
          required: "Please select the timestamp for this expense.",
        })}
        hint={errors.timestamp?.message}
      />

      <Button use="primary" isDisabled={isSaving} label="Save" />
    </form>
  );
}

export const ExpenseFormFragment = gql`
  fragment ExpenseForm_ExpenseCategories on Query {
    ...ExpenseCategorySelector_ExpenseCategories
  }
  ${ExpenseCategoriesFragment}
`;

export default ExpenseForm;
