import React from "react";
import classNames from "classnames";

export type ButtonUse =
  | "primary"
  | "link"
  | "info"
  | "success"
  | "warning"
  | "danger";

export interface Props {
  use?: ButtonUse;
  isDisabled?: boolean;
  label: string;
  onClick?: () => void;
}

function Button({ use, isDisabled = false, label, onClick }: Props) {
  const onButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (onClick != null) {
      event.preventDefault();
      onClick();
    }
  };

  return (
    <button
      disabled={isDisabled}
      className={classNames("button", {
        [`is-${use}`]: use !== undefined,
      })}
      onClick={onButtonClick}
    >
      {label}
    </button>
  );
}

export default Button;
