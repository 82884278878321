import React from "react";
import gql from "graphql-tag";
import InterestedBooksListItemTitleCell, {
  InterestedBooksListItemTitleCellFragment,
} from "./InterestedBooksListItemTitleCell";
import InterestedBooksListItemPriceCell, {
  InterestedBooksListItemPriceCellFragment,
} from "./InterestedBooksListItemPriceCell";
import InterestedBooksListItemOneWeekPriceComparisonCell, {
  InterestedBooksListItemOneWeekPriceComparisonCellFragment,
} from "./InterestedBooksListItemOneWeekPriceComparisonCell";
import InterestedBooksListItemOneMonthPriceComparisonCell, {
  InterestedBooksListItemOneMonthPriceComparisonCellFragment,
} from "./InterestedBooksListItemOneMonthPriceComparisonCell";
import { InterestedBooksListItemFragment_InterestedBookFragment } from "../generated/graphql";
import styles from "./InterestedBooksList.module.css";

interface Props {
  book: InterestedBooksListItemFragment_InterestedBookFragment;
}

function InterestedBooksListItem({ book }: Props) {
  return (
    <div className={styles.row}>
      <InterestedBooksListItemTitleCell book={book} />
      <InterestedBooksListItemPriceCell book={book} />
      <InterestedBooksListItemOneWeekPriceComparisonCell book={book} />
      <InterestedBooksListItemOneMonthPriceComparisonCell book={book} />
    </div>
  );
}

export const InterestedBooksListItemFragment = gql`
  fragment InterestedBooksListItemFragment_InterestedBook on InterestedBook {
    ...InterestedBooksListItemTitleCellFragment_InterestedBook
    ...InterestedBooksListItemPriceCellFragment_InterestedBook
    ...InterestedBooksListItemOneWeekPriceComparisonCellFragment_InterestedBook
    ...InterestedBooksListItemOneMonthPriceComparisonCellFragment_InterestedBook
  }
  ${InterestedBooksListItemTitleCellFragment}
  ${InterestedBooksListItemPriceCellFragment}
  ${InterestedBooksListItemOneWeekPriceComparisonCellFragment}
  ${InterestedBooksListItemOneMonthPriceComparisonCellFragment}
`;

export default InterestedBooksListItem;
