import React from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Page from "./Page";
import TextField from "../components/TextField";
import Button from "../components/Button";
import useEnsureSignedIn from "../hooks/use-ensure-signed-in";
import useNotifications from "../hooks/use-notifications";
import {
  InstapaperAuthorizationPage_InstapaperAuthorizationMutation,
  InstapaperAuthorizationPage_InstapaperAuthorizationMutationVariables,
  NotificationFlashState,
  NotificationType,
} from "../generated/graphql";

const INSTAPAPER_AUTHORIZATION_PAGE_INSTAPAPER_AUTHORIZATION_MUTATION = gql`
  mutation InstapaperAuthorizationPage_InstapaperAuthorization(
    $email: String!
    $password: String!
  ) {
    instapaperAuthorization(email: $email, password: $password)
  }
`;

interface InstapaperFieldValues {
  email: string;
  password: string;
}

function InstapaperAuthorizationPage() {
  useEnsureSignedIn();

  const navigate = useNavigate();

  const { register, handleSubmit, errors } = useForm<InstapaperFieldValues>();

  const [instapaperAuthorization, { loading: isSaving }] = useMutation<
    InstapaperAuthorizationPage_InstapaperAuthorizationMutation,
    InstapaperAuthorizationPage_InstapaperAuthorizationMutationVariables
  >(INSTAPAPER_AUTHORIZATION_PAGE_INSTAPAPER_AUTHORIZATION_MUTATION);

  const { addNotification } = useNotifications();

  const onSubmit = async (values: InstapaperFieldValues) => {
    const { data, errors } = await instapaperAuthorization({
      variables: values,
    });

    // TODO Handle this at link level
    if (errors != null && errors.length > 0) {
      const messages = errors.map(error => error.message);
      alert(`GraphQL errors:\n${messages.join("\n")}`);
      return;
    }

    if (data == null) {
      alert("Empty response from GraphQL mutation.");
      return;
    }

    if (data.instapaperAuthorization !== "ok") {
      alert("Authorization failed");
      return;
    }

    await addNotification(
      "Connected to Instapaper",
      NotificationFlashState.NextPage,
      NotificationType.Success,
    );
    navigate("/services");
  };

  return (
    <Page title="Connect to Instapaper">
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          use={errors.email != null ? "danger" : undefined}
          type="email"
          name="email"
          label="Instapaper Email"
          ref={register({
            required: "Please enter your Instapaper email address.",
          })}
          hint={errors.email?.message}
        />

        <TextField
          use={errors.password != null ? "danger" : undefined}
          type="password"
          name="password"
          label="Instapaper Password"
          ref={register({
            required: "Please enter your Instapaper password.",
          })}
          hint={errors.password?.message}
        />

        <Button use="primary" isDisabled={isSaving} label="Save" />
      </form>
    </Page>
  );
}

export default InstapaperAuthorizationPage;
