import React from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";
import useEnsureSignedIn from "../hooks/use-ensure-signed-in";
import Page from "./Page";
import BunnyCommandForm, {
  BunnyCommandInput,
} from "../components/BunnyCommandForm";
import BunnyCommandsList, {
  BunnyCommandsListFragment,
} from "../components/BunnyCommandsList";
import ModalLauncherButton from "../components/ModalLauncherButton";
import { useModalContext } from "../components/Modal";
import useNotifications from "../hooks/use-notifications";
import flattenArray from "../utils/flatten-array";
import {
  BunnyProfileDetailsPageQuery,
  BunnyProfileDetailsPageQueryVariables,
  CreateBunnyCommandForm_CreateCommandMutation,
  CreateBunnyCommandForm_CreateCommandMutationVariables,
  NotificationFlashState,
  NotificationType,
} from "../generated/graphql";

const BUNNY_PROFILE_DETAILS_PAGE_QUERY = gql`
  query BunnyProfileDetailsPage($id: ID!) {
    bunnyProfile(id: $id) {
      name
      token
      commands {
        nodes {
          ...BunnyCommandsListFragment_BunnyCommand
        }
      }
    }
  }
  ${BunnyCommandsListFragment}
`;

const CREATE_BUNNY_COMMAND_MUTATION = gql`
  mutation CreateBunnyCommandForm_CreateCommand(
    $profileId: ID!
    $trigger: String!
    $url: String!
    $urlTemplate: String
  ) {
    createBunnyCommand(
      profileId: $profileId
      trigger: $trigger
      url: $url
      urlTemplate: $urlTemplate
    ) {
      ... on ValidationErrors {
        errors {
          field
          messages
        }
      }
    }
  }
`;

interface CreateBunnyCommandFormProps {
  profileId: string;
}

function CreateBunnyCommandForm({ profileId }: CreateBunnyCommandFormProps) {
  const modal = useModalContext();
  const { addNotification } = useNotifications();

  const [createCommand, { loading: isSaving }] = useMutation<
    CreateBunnyCommandForm_CreateCommandMutation,
    CreateBunnyCommandForm_CreateCommandMutationVariables
  >(CREATE_BUNNY_COMMAND_MUTATION, {
    refetchQueries: ["BunnyProfileDetailsPage"],
  });

  const onSubmit = async (command: BunnyCommandInput) => {
    const { data, errors } = await createCommand({
      variables: { ...command, profileId },
    });

    // TODO Handle this at link level
    if (errors != null && errors.length > 0) {
      const messages = errors.map(error => error.message);
      alert(`GraphQL errors:\n${messages.join("\n")}`);
      return;
    }

    if (data == null) {
      alert("Empty response from GraphQL mutation.");
      return;
    }

    if (data.createBunnyCommand?.__typename === "ValidationErrors") {
      const errors = data.createBunnyCommand.errors;
      const messages = errors.flatMap(({ field, messages }) => {
        return messages.map(message => `${field} ${message}`);
      });
      alert(`Validation errors:\n${messages.join("\n")}`);
      return;
    }

    await addNotification(
      "Command created",
      NotificationFlashState.CurrentPage,
      NotificationType.Success,
    );
    modal.onHide();
  };

  return (
    <div className="box">
      <BunnyCommandForm onSubmit={onSubmit} isSaving={isSaving} />
    </div>
  );
}

function BunnyProfileDetailsPage() {
  useEnsureSignedIn();

  const { id } = useParams();

  const { data } = useQuery<
    BunnyProfileDetailsPageQuery,
    BunnyProfileDetailsPageQueryVariables
  >(BUNNY_PROFILE_DETAILS_PAGE_QUERY, { variables: { id: id! } });

  if (data == null) {
    return <div>Loading...</div>;
  }

  const profile = data.bunnyProfile;
  if (profile == null) {
    return <div>Unknown profile</div>;
  }

  const commands = flattenArray(profile.commands.nodes);

  return (
    <Page title="Bunny Profile">
      <div>Profile: {profile.name}</div>
      <pre>
        <code>https://api.pa.yihangho.com/bunny/{profile.token}?q=%s</code>
      </pre>
      <hr />
      <ModalLauncherButton use="success" label="New Command">
        <CreateBunnyCommandForm profileId={id!} />
      </ModalLauncherButton>
      <BunnyCommandsList commands={commands} />
    </Page>
  );
}

export default BunnyProfileDetailsPage;
