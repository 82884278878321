import React, { useEffect } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useLocation } from "react-router-dom";
import Notification, { NotificationUse } from "./Notification";
import useNotifications from "../hooks/use-notifications";
import {
  GlobalNotificationsQuery,
  GlobalNotificationsQueryVariables,
  GlobalNotifications_UpdateFlashStateMutation,
  GlobalNotifications_UpdateFlashStateMutationVariables,
  NotificationType,
} from "../generated/graphql";

const GLOBAL_NOTIFICATIONS_QUERY = gql`
  query GlobalNotifications {
    notifications @client {
      id
      message
      type
    }
  }
`;

const GLOBAL_NOTIFICATIONS_UPDATE_FLASH_STATE_MUTATION = gql`
  mutation GlobalNotifications_UpdateFlashState {
    updateNotificationsFlashState @client {
      __typename
    }
  }
`;

const TYPE_TO_USE: Record<NotificationType, NotificationUse> = {
  [NotificationType.Primary]: "primary",
  [NotificationType.Success]: "success",
  [NotificationType.Warning]: "warning",
  [NotificationType.Danger]: "danger",
};

function GlobalNotifications() {
  const { removeNotification } = useNotifications();

  const { data } = useQuery<
    GlobalNotificationsQuery,
    GlobalNotificationsQueryVariables
  >(GLOBAL_NOTIFICATIONS_QUERY);

  const [updateFlashState] = useMutation<
    GlobalNotifications_UpdateFlashStateMutation,
    GlobalNotifications_UpdateFlashStateMutationVariables
  >(GLOBAL_NOTIFICATIONS_UPDATE_FLASH_STATE_MUTATION);

  const { pathname } = useLocation();
  useEffect(() => {
    updateFlashState();
  }, [pathname, updateFlashState]);

  const notifications = data?.notifications ?? [];

  const makeOnClose = (id: string) => () => {
    removeNotification(id);
  };

  return (
    <div>
      {notifications.map(notification => (
        <Notification
          key={notification.id}
          use={
            notification.type != null
              ? TYPE_TO_USE[notification.type]
              : undefined
          }
          onClose={makeOnClose(notification.id)}
        >
          {notification.message}
        </Notification>
      ))}
    </div>
  );
}

export default GlobalNotifications;
