import React, { ReactNode } from "react";
import gql from "graphql-tag";
import Select from "./Select";
import { FormFieldState } from "./form-field-state";
import { ExpenseCategorySelector_ExpenseCategoriesFragment } from "../generated/graphql";

interface Props {
  use?: FormFieldState;
  query: ExpenseCategorySelector_ExpenseCategoriesFragment;
  label: string;
  name: string;
  hint?: ReactNode;
}

function ExpenseCategorySelector(
  { use, query, label, name, hint }: Props,
  ref?: React.Ref<HTMLSelectElement>,
) {
  const options = query.expenseCategories.map(({ id, title }) => ({
    value: id,
    label: title,
  }));

  return (
    <Select
      ref={ref}
      use={use}
      label={label}
      name={name}
      options={options}
      promptLabel="Select a category"
      hint={hint}
    />
  );
}

export const ExpenseCategoriesFragment = gql`
  fragment ExpenseCategorySelector_ExpenseCategories on Query {
    expenseCategories {
      id
      title
    }
  }
`;

export default React.forwardRef(ExpenseCategorySelector);
