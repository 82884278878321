import React from "react";
import { Link } from "react-router-dom";
import { MenuItem } from "../configs/application-menus";
import styles from "./ApplicationMenu.module.css";

interface Props {
  menuItems: MenuItem[];
}

function ApplicationMenu({ menuItems }: Props) {
  return (
    <div className={styles.container}>
      {menuItems.map((item, idx) => (
        <Link key={idx} className={styles.link} to={item.to}>
          {item.title}
        </Link>
      ))}
    </div>
  );
}

export default ApplicationMenu;
