import React, { ReactNode } from "react";
import classNames from "classnames";
import { FormFieldState } from "./form-field-state";

interface Option<T> {
  value: T;
  label: string;
}

export interface Props<T> {
  use?: FormFieldState;
  label: string;
  name: string;
  options: Option<T>[];
  promptLabel?: string;
  hint?: ReactNode;
}

// TODO This is written as an uncontrolled component with
// react-hook-form in mind. If it makes sense, we can spend
// time thinking of a good API to make it work for both.
function Select<T extends string>(
  { use, label, name, options, promptLabel, hint }: Props<T>,
  ref?: React.Ref<HTMLSelectElement>,
) {
  const children = options.map(({ value, label }) => (
    <option key={value} value={value}>
      {label}
    </option>
  ));

  return (
    <div className="field">
      <label className="label">{label}</label>
      <div className="control">
        <div
          className={classNames("select", {
            [`is-${use}`]: use != null,
          })}
        >
          <select ref={ref} name={name}>
            {promptLabel != null && (
              <option value="" disabled={true}>
                {promptLabel}
              </option>
            )}
            {children}
          </select>
        </div>
      </div>
      {hint != null && (
        <p
          className={classNames("help", {
            [`is-${use}`]: use != null,
          })}
        >
          {hint}
        </p>
      )}
    </div>
  );
}

export default React.forwardRef(Select);
