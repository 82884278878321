import React, { useMemo } from "react";
import gql from "graphql-tag";
import ExpensesList, { ExpensesListFragment } from "./ExpensesList";
import ExpenseBreakdownPieChart, {
  ExpenseBreakdownPieChartFragment,
} from "./ExpenseBreakdownPieChart";
import ExpenseReportBudgetSection, {
  ExpenseReportBudgetSectionFragment,
} from "./ExpenseReportBudgetSection";
import { Provider } from "../context/expense-report-metadata";
import flattenArray from "../utils/flatten-array";
import { ExpenseReport_ExpenseReportFragment } from "../generated/graphql";

interface Props {
  expenseReport: ExpenseReport_ExpenseReportFragment;
  isLoadingMore: boolean;
  loadMore?: () => void;
}

function ExpenseReport({ expenseReport, isLoadingMore, loadMore }: Props) {
  const metadata = useMemo(
    () => ({
      from: expenseReport.from,
      to: expenseReport.to,
      currency: expenseReport.currency,
    }),
    [expenseReport],
  );

  const expenses = flattenArray(expenseReport.expenses.nodes);

  return (
    <Provider value={metadata}>
      <div>
        <div className="message">
          <div className="message-header">
            <p>
              Expenses ({expenseReport.from} - {expenseReport.to})
            </p>
          </div>
          <div className="message-body">
            Total: {expenseReport.currency} {expenseReport.total.toFixed(2)}
            <ExpenseBreakdownPieChart expenseReport={expenseReport} />
          </div>
        </div>

        <ExpenseReportBudgetSection expenseReport={expenseReport} />

        <ExpensesList
          expenses={expenses}
          isLoadingMore={isLoadingMore}
          loadMore={loadMore}
        />
      </div>
    </Provider>
  );
}

export const ExpenseReportFragment = gql`
  fragment ExpenseReport_ExpenseReport on ExpenseReport {
    from
    to
    currency
    total
    expenses(after: $after) {
      nodes {
        ...ExpensesListFragment_Expense
      }
    }
    ...ExpenseBreakdownPieChart_ExpenseReport
    ...ExpenseReportBudgetSection_ExpenseReport
  }
  ${ExpensesListFragment}
  ${ExpenseBreakdownPieChartFragment}
  ${ExpenseReportBudgetSectionFragment}
`;

export default ExpenseReport;
