import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ApolloProvider } from "@apollo/react-hooks";
import GlobalNotifications from "./components/GlobalNotifications";
import IndexPage from "./pages/IndexPage";
import SignInPage from "./pages/SignInPage";
import RedirectToCurrentMonthExpenseReport from "./pages/RedirectToCurrentMonthExpenseReport";
import ExpensesListPage from "./pages/ExpensesListPage";
import NewExpensePage from "./pages/NewExpensePage";
import MontlyExpenseReportPage from "./pages/MonthlyExpenseReportPage";
import RecurringExpensesListPage from "./pages/RecurringExpensesListPage";
import NewRecurringExpensePage from "./pages/NewRecurringExpensePage";
import ExpenseCategoriesListPage from "./pages/ExpenseCategoriesListPage";
import NewExpenseCategoryPage from "./pages/NewExpenseCategoryPage";
import EditSimpleBudgetPage from "./pages/EditSimpleBudgetPage";
import InterestedBooksListPage from "./pages/InterestedBooksListPage";
import NewInterestedBookPage from "./pages/NewInterestedBookPage";
import InterestedBookDetailsPage from "./pages/InterestedBookDetailsPage";
import BunnyProfilesListPage from "./pages/BunnyProfilesListPage";
import BunnyProfileDetailsPage from "./pages/BunnyProfileDetailsPage";
import EbooksSetupPage from "./pages/EbooksSetupPage";
import ServicesListPage from "./pages/ServicesListPage";
import InstapaperAuthorizationPage from "./pages/InstapaperAuthorizationPage";
import PocketAuthorizationPage from "./pages/PocketAuthorizationPage";
import PocketAuthorizationCallbackPage from "./pages/PocketAuthorizationCallbackPage";
import client from "./apollo-client";
import "./font-awesome-library";

function App() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <GlobalNotifications />
        <Routes>
          <Route path="/" element={<IndexPage />} />
          <Route path="/sign_in" element={<SignInPage />} />
          <Route
            path="/expenses"
            element={<RedirectToCurrentMonthExpenseReport />}
          />
          <Route path="/expenses/all" element={<ExpensesListPage />} />
          <Route path="/expenses/new" element={<NewExpensePage />} />
          <Route
            path="/expenses/monthly/:year/:month"
            element={<MontlyExpenseReportPage />}
          />
          <Route
            path="/expenses/recurring"
            element={<RecurringExpensesListPage />}
          />
          <Route
            path="/expenses/recurring/new"
            element={<NewRecurringExpensePage />}
          />
          <Route
            path="/expenses/categories"
            element={<ExpenseCategoriesListPage />}
          />
          <Route
            path="/expenses/categories/new"
            element={<NewExpenseCategoryPage />}
          />
          <Route
            path="/expenses/categories/:id/budget"
            element={<EditSimpleBudgetPage />}
          />
          <Route
            path="/interested_books"
            element={<InterestedBooksListPage />}
          />
          <Route
            path="/interested_books/new"
            element={<NewInterestedBookPage />}
          />
          <Route
            path="/interested_books/:id"
            element={<InterestedBookDetailsPage />}
          />
          <Route path="/bunny/profiles" element={<BunnyProfilesListPage />} />
          <Route
            path="/bunny/profiles/:id"
            element={<BunnyProfileDetailsPage />}
          />
          <Route
            path="/text_highlights/setup/ebooks"
            element={<EbooksSetupPage />}
          />
          <Route path="/services" element={<ServicesListPage />} />
          <Route
            path="/services/instapaper"
            element={<InstapaperAuthorizationPage />}
          />
          <Route
            path="/services/pocket"
            element={<PocketAuthorizationPage />}
          />
          <Route
            path="/services/pocket/callback"
            element={<PocketAuthorizationCallbackPage />}
          />
        </Routes>
      </Router>
    </ApolloProvider>
  );
}

export default App;
