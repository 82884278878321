import React from "react";
import gql from "graphql-tag";
import classNames from "classnames";
import parse from "date-fns/parse";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import isWithinInterval from "date-fns/isWithinInterval";
import ProgressBar, { ProgressBarUse } from "./ProgressBar";
import { useExpenseReportMetadata } from "../context/expense-report-metadata";
import {
  SimpleBudgetPeriod,
  ExpenseReportBudgetItem_CategoricalExpenseReportFragment,
} from "../generated/graphql";
import styles from "./ExpenseReportBudgetItem.module.css";

interface Props {
  categoricalExpenseReport: ExpenseReportBudgetItem_CategoricalExpenseReportFragment;
}

function ExpenseReportBudgetItem({ categoricalExpenseReport }: Props) {
  const { from, to, currency } = useExpenseReportMetadata();

  const { total, category } = categoricalExpenseReport;
  const budget = category.simpleBudget;

  if (budget == null) {
    return null;
  }

  const fromDate = startOfDay(parse(from, "yyyy-MM-dd", new Date()));
  const toDate = endOfDay(parse(to, "yyyy-MM-dd", new Date()));
  const days = differenceInCalendarDays(toDate, fromDate) + 1;
  const daysElapsed = differenceInCalendarDays(new Date(), fromDate) + 1;

  const isTodayWithinInterval = isWithinInterval(new Date(), {
    start: fromDate,
    end: toDate,
  });

  const budgetAmount = budget.convertedAmount ?? budget.amount;
  const budgetCurrency =
    budget.convertedAmount != null ? currency : budget.currency;
  const availableBudgetAmount =
    budget.period === SimpleBudgetPeriod.Daily
      ? budgetAmount * days
      : budgetAmount;

  const numerator = `${currency} ${total.toFixed(2)}`;
  const denominator =
    currency !== budgetCurrency
      ? `${budgetCurrency} ${availableBudgetAmount.toFixed(2)}`
      : availableBudgetAmount.toFixed(2);

  const use = ((): ProgressBarUse => {
    if (total > availableBudgetAmount) {
      return "danger";
    }

    if (!isTodayWithinInterval) {
      return "success";
    }

    if (total / availableBudgetAmount > daysElapsed / days) {
      return "warning";
    }

    return "success";
  })();

  return (
    <div className={classNames("card", styles.container)}>
      <header className="card-header">
        <p className="card-header-title">{category.title}</p>
      </header>
      <div className="card-content">
        <div className="content">
          <p>
            {numerator} / {denominator}
          </p>
          {currency === budgetCurrency && (
            <ProgressBar
              use={use}
              value={total / availableBudgetAmount}
              milestones={isTodayWithinInterval ? [daysElapsed / days] : []}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export const ExpenseReportBudgetItemFragment = gql`
  fragment ExpenseReportBudgetItem_CategoricalExpenseReport on CategoricalExpenseReport {
    total
    category {
      title
      simpleBudget {
        currency
        amount
        convertedAmount(currency: $currency)
        period
      }
    }
  }
`;

export default ExpenseReportBudgetItem;
