import React from "react";

interface Props {
  name: string;
  label: string;
}

function Checkbox({ name, label }: Props, ref?: React.Ref<HTMLInputElement>) {
  return (
    <div className="field">
      <label className="checkbox">
        <input type="checkbox" name={name} ref={ref} /> {label}
      </label>
    </div>
  );
}

export default React.forwardRef(Checkbox);
