import React from "react";
import gql from "graphql-tag";
import Tippy from "@tippyjs/react";
import { InterestedBooksListItemPriceCellFragment_InterestedBookFragment } from "../generated/graphql";
import styles from "./InterestedBooksList.module.css";
import "tippy.js/dist/tippy.css";

interface Props {
  book: InterestedBooksListItemPriceCellFragment_InterestedBookFragment;
}

function InterestedBooksListItemPriceCell({ book }: Props) {
  const { latestPrice } = book;
  if (latestPrice != null) {
    return (
      <Tippy content={`Price on ${latestPrice.date}`}>
        <div className={styles.priceCell}>
          <span className={styles.inlineLabel}>Price: </span>$
          {latestPrice.price.toFixed(2)}
        </div>
      </Tippy>
    );
  } else {
    return (
      <div className={styles.priceCell}>
        <span className={styles.inlineLabel}>Price: </span>N/A
      </div>
    );
  }
}

export const InterestedBooksListItemPriceCellFragment = gql`
  fragment InterestedBooksListItemPriceCellFragment_InterestedBook on InterestedBook {
    latestPrice: price {
      date
      price
    }
  }
`;

export default InterestedBooksListItemPriceCell;
