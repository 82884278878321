import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function RedirectToCurrentMonthExpenseReport() {
  const navigate = useNavigate();

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1; // getMonth is 0-based
    navigate(`/expenses/monthly/${year}/${month}`);
  }, [navigate]);

  return <div />;
}

export default RedirectToCurrentMonthExpenseReport;
