import React, { useEffect } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { useNavigate } from "react-router-dom";
import Page from "./Page";
import useEnsureSignedIn from "../hooks/use-ensure-signed-in";
import useNotifications from "../hooks/use-notifications";
import {
  PocketAuthorizationCallbackPage_PocketAuthorizationMutation,
  PocketAuthorizationCallbackPage_PocketAuthorizationMutationVariables,
  NotificationFlashState,
  NotificationType,
} from "../generated/graphql";

const POCKET_AUTHORIZATION_CALLBACK_PAGE_POCKET_AUTHORIZATION_MUTATION = gql`
  mutation PocketAuthorizationCallbackPage_PocketAuthorization {
    pocketAuthorization
  }
`;

function PocketAuthorizationCallbackPage() {
  useEnsureSignedIn();

  const navigate = useNavigate();

  const [authorize] = useMutation<
    PocketAuthorizationCallbackPage_PocketAuthorizationMutation,
    PocketAuthorizationCallbackPage_PocketAuthorizationMutationVariables
  >(POCKET_AUTHORIZATION_CALLBACK_PAGE_POCKET_AUTHORIZATION_MUTATION);

  const { addNotification } = useNotifications();

  useEffect(() => {
    let mounted = true;

    (async () => {
      const { data, errors } = await authorize();

      if (!mounted) {
        return;
      }

      // TODO Handle this at link level
      if (errors != null && errors.length > 0) {
        const messages = errors.map(error => error.message);
        alert(`GraphQL errors:\n${messages.join("\n")}`);
        navigate("/services/pocket");
        return;
      }

      if (data == null) {
        alert("Empty response from GraphQL mutation.");
        navigate("/services/pocket");
        return;
      }

      if (data.pocketAuthorization !== "ok") {
        alert(`Authorization failed: ${data.pocketAuthorization}`);
        navigate("/services/pocket");
        return;
      }

      await addNotification(
        "Connected to Pocket",
        NotificationFlashState.NextPage,
        NotificationType.Success,
      );
      navigate("/services");
    })();

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title="Pocket Authorization">
      <div>Loading...</div>
    </Page>
  );
}

export default PocketAuthorizationCallbackPage;
