import React from "react";
import classNames from "classnames";
import styles from "./ProgressBar.module.css";

export type ProgressBarUse =
  | "primary"
  | "link"
  | "info"
  | "success"
  | "warning"
  | "danger";

interface Props {
  use?: ProgressBarUse;
  value: number;
  milestones?: number[];
}

function ProgressBar({ use, value, milestones = [] }: Props) {
  return (
    <div className={styles.container}>
      <progress
        className={classNames("progress", {
          [`is-${use}`]: use != null,
        })}
        value={value}
      />
      {milestones.map((val, idx) => (
        <div
          key={idx}
          className={styles.milestone}
          style={{ left: `${val * 100}%` }}
        />
      ))}
    </div>
  );
}

export default ProgressBar;
